export const tempTermData = [
    {
        term_name: '개인정보 처리방침',
        requ_yesn: true,
        term_memo: `
            <div class="term-container">
                <h1>개인정보 처리방침</h1>
                
                <p>저희 <strong>미래씨엔엘</strong>은 전기차 충전소 및 충전기 이용과 관련된 서비스를 제공하기 위해 다음과 같은 개인정보를 수집하고 있습니다:</p>
                
                <h2>1. 개인정보의 수집 및 이용 목적</h2>
                <ul>
                    <li><strong>사용자명</strong>: 서비스 이용 시 사용자 식별 및 개인화된 경험 제공</li>
                    <li><strong>연락처</strong>: 사용자와의 연락 및 서비스 제공 관련 정보 전달</li>
                    <li><strong>이메일</strong>: 아이디, 비밀번호 재설정, 사용자 식별 등</li>
                </ul>
                
                <h2>2. 결제 정보</h2>
                <p><strong>카드정보</strong>: 결제 시스템을 통해 수집되며, PG사(결제 대행사)를 통해 처리됩니다. 카드 정보는 저희 서버에 저장되지 않으며, 결제 과정에서 PG사의 안전한 결제 시스템을 통해 암호화 및 처리됩니다.</p>
                
                <h2>3. 개인정보의 보유 및 이용 기간</h2>
                <ul>
                    <li><strong>서비스 제공 기간 동안</strong>: 개인정보는 서비스 제공 및 고객 지원을 위해 보유됩니다.</li>
                    <li><strong>법적 의무 이행을 위한 기간</strong>: 관련 법규에 따라 필요한 경우 법적 의무 이행을 위해 보유됩니다.</li>
                </ul>
                
                <h2>4. 개인정보의 제3자 제공</h2>
                <p><strong>PG사</strong>: 결제 처리를 위해 카드 정보는 결제 대행사에 제공됩니다. 이 외의 제3자에게는 제공되지 않습니다.</p>
                
                <h2>5. 개인정보의 안전성 확보 조치</h2>
                <ul>
                    <li><strong>데이터 암호화</strong>: 개인정보는 전송 및 저장 시 암호화되어 보호됩니다.</li>
                    <li><strong>접근 제어</strong>: 개인정보에 접근할 수 있는 권한을 제한하고, 보안 시스템을 유지 관리합니다.</li>
                </ul>
                
                <h2>6. 사용자의 권리와 요청</h2>
                <ul>
                    <li><strong>정보 접근 및 수정</strong>: 사용자는 언제든지 자신의 개인정보에 접근하고 수정할 수 있습니다.</li>
                    <li><strong>삭제 요청</strong>: 개인정보의 삭제를 요청할 수 있으며, 관련 법규에 따라 처리됩니다.</li>
                </ul>
                
                <h2>7. 개인정보 처리방침 변경</h2>
                <ul>
                    <li><strong>정기 검토</strong>: 개인정보 처리방침은 정기적으로 검토되며, 변경 사항은 웹사이트를 통해 공지됩니다.</li>
                    <li><strong>변경 일자</strong>: 개인정보 처리방침이 변경되는 경우 최소 7일 전 변경 사항을 사전에 안내 하겠습니다.</li>
                    <li><strong>변경 일자</strong>: 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 미리 알려드리겠습니다.</li>
                </ul>
                
                <h2>8. 기타</h2>
                <p><strong>법적 요구사항</strong>: 본 개인정보 처리방침은 대한민국의 법적 요구사항을 준수하며, 필요에 따라 변경될 수 있습니다.</p>
                <p>미래씨엔엘은 이용자 여러분의 정보를 소중히 생각하며, 이용자가 더욱 안심하고 서비스를 이용할 수 있도록 최선의 노력을 다할것을 약속드립니다.</p>
                <ul>
                    <li><strong>공고일자</strong>: 2024년 08월 16일</li>
                    <li><strong>시행일자</strong>: 2024년 08월 23일</li>
                </ul>
            </div>
        `
    },
    {
        term_name: '서비스 이용약관',
        requ_yesn: true,
        term_memo: `
            <div class="term-container">
                <h1>서비스 이용약관</h1>
                
                <p>본 이용약관(이하 "약관")은 <strong>[회사명]</strong> (이하 "회사")가 제공하는 전기차 충전소 및 충전기 이용 서비스(이하 "서비스")의 이용에 관한 조건을 규정합니다. 서비스를 이용함으로써, 귀하는 본 약관에 동의하는 것으로 간주됩니다.</p>
                
                <h2>1. 서비스의 이용</h2>
                <ul>
                    <li>회사는 전기차 충전소 및 충전기 이용 서비스를 제공하며, 이를 통해 사용자에게 충전소와 충전기의 위치 및 이용 정보 등을 제공합니다.</li>
                    <li>서비스의 이용자는 회사의 약관 및 정책을 준수해야 합니다.</li>
                </ul>
                
                <h2>2. 회원 가입 및 관리</h2>
                <ul>
                    <li>서비스 이용을 위해서는 회원 가입이 필요할 수 있습니다. 회원 가입 시 제공한 정보는 정확하고 최신의 정보여야 합니다.</li>
                    <li>회원은 자신의 계정 정보를 보호할 책임이 있으며, 계정의 불법 사용에 대해 회사는 책임지지 않습니다.</li>
                </ul>
                
                <h2>3. 이용 요금</h2>
                <ul>
                    <li>서비스 이용에 따른 요금은 충전소 및 충전기 이용 시의 요금 정책에 따라 부과됩니다.</li>
                    <li>요금 결제는 PG사를 통한 안전한 결제 시스템을 통해 이루어집니다. 카드 정보는 회사 서버에 저장되지 않습니다.</li>
                </ul>
                
                <h2>4. 개인정보 보호</h2>
                <p>회사는 이용자의 개인정보를 보호하기 위해 관련 법규를 준수하며, 개인정보 처리방침에 따라 수집된 정보를 안전하게 관리합니다.</p>
                
                <h2>5. 서비스의 변경 및 중단</h2>
                <ul>
                    <li>회사는 서비스의 내용, 기능, 요금 등을 서비스 화면을 통하여 이를 공지하거나 전자우편, 기타의 방법으로 회원에게 통지함으로써 효력이 발생됩니다.</li>
                    <li>본 약관에 동의하는 것은 정기적으로 회사가 운영하는 미래씨엔엘 앱 및 사이트를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 회사는 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원 또는 이용자의 피해에 대하여 책임을 부담하지 않습니다.</li>
                </ul>
                
                <h2>6. 사용자 의무</h2>
                <ul>
                    <li>이용자는 서비스 이용 시 타인에게 피해를 주거나 법령을 위반하는 행위를 해서는 안 됩니다.</li>
                    <li>회사는 이용자가 본 약관을 위반하여 발생한 손해에 대해 법적 조치를 취할 수 있습니다.</li>
                </ul>
                
                <h2>7. 면책 조항</h2>
                <ul>
                    <li>회사는 서비스 이용 중 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 이는 서비스의 사용 또는 사용 불가능으로 인해 발생한 손해를 포함합니다.</li>
                    <li>회사는 기술적 문제로 인해 서비스가 일시적으로 중단될 수 있으며, 이로 인한 손해에 대해 책임지지 않습니다.</li>
                </ul>
                
                <h2>8. 약관의 변경</h2>
                <ul>
                    <li>본 약관은 '약관의 규제에 관한 법률', '정보통신망 이용 촉진 및 정보보호에 관한 법률'등 관련법을 위배하지 않는 범위에서 수시로 개정이 가능합니다. 회사는 본 약관을 개정하고자 할 경우, 적용일자 및 개정사유를 명시하여 개정된 약관을 적용하고자 하는 날(이하 "효력 발생일")로부터 7일 이전에 약관이 개정된다는 사실과 개정된 내용 등을 미래씨엔엘 앱 및 사이트에 공지합니다. 다만, 회원에게 불리하거나 중요한 약관 내용의 개정의 경우에는 효력 발생일로부터 30일 전에 홈페이지에 현행 약관과 함께 공지합니다.</li>
                    <li>변경된 약관은 공지 후 효력을 발생합니다.</li>
                </ul>
                
                <h2>9. 법적 효력</h2>
                <ul>
                    <li>본 약관은 대한민국의 법적 요구사항을 준수하며, 법적 효력을 가집니다.</li>
                    <li>본 약관의 조항 중 일부가 무효가 되더라도 나머지 조항은 여전히 유효합니다.</li>
                </ul>
            </div>
        `
    },    
    {
        term_name: '위치 기반 서비스 이용약관',
        requ_yesn: false,
        term_memo: `
            <div class="term-container">
                <h1>위치 기반 서비스 이용약관</h1>
                
                <p>본 이용약관(이하 "약관")은 <strong>미래씨엔엘</strong> (이하 "회사")이 제공하는 위치 기반 서비스(이하 "서비스")의 이용에 관한 조건을 규정합니다. 서비스를 이용함으로써, 귀하는 본 약관에 동의하는 것으로 간주됩니다.</p>
                
                <h2>1. 서비스의 이용</h2>
                <ul>
                    <li>회사는 사용자의 위치 정보를 기반으로 전기차 충전소 및 충전기 위치 정보를 제공합니다.</li>
                    <li>서비스 이용자는 위치 정보의 수집 및 이용에 동의해야 합니다.</li>
                </ul>
                
                <h2>2. 위치 정보의 수집 및 이용</h2>
                <ul>
                    <li><strong>위치 정보</strong>: 사용자의 동의 하에 위치 정보를 수집합니다. 이 정보는 충전소 및 충전기 찾기, 경로 안내 등의 서비스를 제공하기 위해 사용됩니다.</li>
                    <li>위치 정보는 서비스의 성능 향상 및 사용자 경험 개선을 위해 사용됩니다.</li>
                </ul>
                
                <h2>3. 위치 정보의 보유 및 이용 기간</h2>
                <ul>
                    <li><strong>서비스 제공 기간 동안</strong>: 위치 정보는 서비스 제공 및 개선을 위해 보유됩니다.</li>
                    <li><strong>법적 의무 이행을 위한 기간</strong>: 관련 법규에 따라 필요한 경우 법적 의무 이행을 위해 보유됩니다.</li>
                </ul>
                
                <h2>4. 위치 정보의 제3자 제공</h2>
                <p>위치 정보는 서비스의 기능을 제공하기 위해 제3자에게 제공될 수 있습니다. 예를 들어, 지도 서비스 제공업체에 위치 정보를 제공할 수 있습니다. 이 외의 제3자에게는 제공되지 않습니다.</p>
                
                <h2>5. 개인정보 및 위치 정보의 안전성 확보 조치</h2>
                <ul>
                    <li><strong>데이터 암호화</strong>: 위치 정보 및 개인정보는 전송 및 저장 시 암호화되어 보호됩니다.</li>
                    <li><strong>접근 제어</strong>: 위치 정보에 접근할 수 있는 권한을 제한하고, 보안 시스템을 유지 관리합니다.</li>
                </ul>
                
                <h2>6. 사용자의 권리와 요청</h2>
                <ul>
                    <li><strong>위치 정보 접근 및 수정</strong>: 사용자는 언제든지 자신의 위치 정보에 접근하고 수정할 수 있습니다.</li>
                    <li><strong>삭제 요청</strong>: 위치 정보의 삭제를 요청할 수 있으며, 관련 법규에 따라 처리됩니다.</li>
                </ul>
                
                <h2>7. 약관의 변경</h2>
                <ul>
                    <li><strong>정기 검토</strong>: 본 약관은 정기적으로 검토되며, 변경 사항은 웹사이트를 통해 공지됩니다.</li>
                    <li><strong>변경 일자</strong>: 약관의 변경이 있을 경우, 변경된 내용을 공지하며, 사용자는 변경된 내용을 확인하고 동의할 수 있습니다.</li>
                </ul>
                
                <h2>8. 법적 효력</h2>
                <ul>
                    <li>본 약관은 [국가/지역]의 법적 요구사항을 준수하며, 법적 효력을 가집니다.</li>
                    <li>본 약관의 조항 중 일부가 무효가 되더라도 나머지 조항은 여전히 유효합니다.</li>
                </ul>
            </div>
        `
    },    
];
