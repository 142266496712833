function wrapWithSpan(text) {
    return text.split("").map((char, index) => (
        <span key={index} className={char === " " ? "space" : ""} style={{ '--i': index }}>
            {char === " " ? "\u00A0" : char}  { }
        </span>
    ));
}

export const homeUtil = {
    wrapWithSpan: wrapWithSpan,
}

const tempNews = [
    {
        thum_imgs: '/image/news/busi-star.png',
        news_titl: '새로운 전기차 충전소 오픈!',
        news_cont: '우리 지역에 새로운 전기차 충전소가 오픈했습니다. 미래스피더와 함께 빠르고 효율적인 충전을 이용하세요.',
        news_date: '2024-09-01',
    },
    {
        thum_imgs: '/image/news/busi-star.png',
        news_titl: '충전소 요금 인하 안내',
        news_cont: '충전소의 요금이 인하되었습니다. 더 저렴하게 미래스피더의 충전 서비스를 이용하실 수 있습니다.',
        news_date: '2024-08-20',
    },
    {
        thum_imgs: '/image/news/busi-star.png',
        news_titl: '전기차 충전소 이용 시간 변경',
        news_cont: '충전소의 운영 시간이 변경되었습니다. 새 운영 시간은 매일 오전 7시부터 오후 11시까지입니다.',
        news_date: '2024-08-15',
    },
    {
        thum_imgs: '/image/news/busi-star.png',
        news_titl: '전기차 충전소 점검 및 유지보수 공지',
        news_cont: '전기차 충전소의 정기 점검 및 유지보수가 예정되어 있습니다. 점검 기간 동안 충전소 이용이 제한될 수 있습니다.',
        news_date: '2024-08-10',
    },
    {
        thum_imgs: '/image/news/busi-star.png',
        news_titl: '전기차 충전소 점검 및 유지보수 공지',
        news_cont: '전기차 충전소의 정기 점검 및 유지보수가 예정되어 있습니다. 점검 기간 동안 충전소 이용이 제한될 수 있습니다.',
        news_date: '2024-08-10',
    },    
];


export const homeData = {
    tempNews: tempNews,
}