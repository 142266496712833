import React from "react";
import { PaysCard } from "../../../script/components/pays-card/pays-card";

const handleWheel = (event) => {
    const container = event.currentTarget;
    if (event.deltaY > 0) {
        container.scrollLeft += 100;
    } else {
        container.scrollLeft -= 100;
    }
};

export default function UserCardInfoSection(props) {
    const { cardList, insertCard, updateCard, deleteCard, starClick  } = props;

    return (
        <div className={'fw-profile-pays-card-container'}>
            <div className={'fw-profile-pays-card-title'}>결제 카드정보</div>
            <div className={'fw-profile-pays-card-list-container only-wheel'} onWheel={handleWheel}>
                <PaysCard
                    type={"default"}
                    ins={() => insertCard(true)}
                />
                {cardList && cardList.length > 0 && cardList.map((item, index) => (
                <PaysCard
                    key={index}                         
                    data={item}
                    upd={(data) => updateCard(data)}
                    del={(data) => deleteCard(data)}
                    starClick={starClick}
                />
                ))}
            </div>
        </div>      
    )
}