import React, { useCallback, useState } from "react";
import { PopupWarn } from "../popup/popup";
import { AccountFormButton } from "./account-panel-item";
import { Link } from "react-router-dom";

export function FormJoinWays({ onJoinLeveBtnsClick }) {
    // 페이지 상태값
    const [loading, setLoading] = useState(false);
    // 안내 팝업
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);
    /*
    const onCreateWithkakao = useCallback(() => {
        setLoading(true);
        setAlertVisible(true);
        setLoading(false);
    }, []);

    const onCreateWithNaver = useCallback(() => {
        setLoading(true);
        setAlertVisible(true);
        setLoading(false);
    }, []);*/

    return (
        <div className='auth-ways-container'>
            <div className="auth-ways-mess">가입하실 방법을 선택해주세요</div>
            <AccountFormButton
                loading={loading}
                className={'first-btn'}
                type={'normal'}
                onClick={() => onJoinLeveBtnsClick(2)}
            >
                <div className='fw-button-content'>
                    <i className='dx-icon-email'></i>
                    <span>이메일로 가입하기</span>
                </div>
            </AccountFormButton>
            {/*
            <div className='sns-login-devider'>SNS 계정이 있으신가요?</div>
            <AccountFormButton
                loading={loading}
                className={'account-btn kakao'}
                type={'default'}
                onClick={onCreateWithkakao}
            >
                <div className='fw-button-content'>
                    <i className='dx-icon-kakao'></i>
                    <span>카카오 로그인</span>
                </div>
            </AccountFormButton>
            <AccountFormButton
                loading={loading}
                className={'account-btn naver'}
                type={'default'}
                onClick={onCreateWithNaver}
            >
                <div className='fw-button-content'>
                    <i className='dx-icon-naver'></i>
                    <span>네이버 로그인</span>
                </div>
            </AccountFormButton>
            */}
            <div className={'link'}>
                계정이 기억나지 않으신가요?<Link to={'/auth/findi'}>아이디 찾기</Link>&nbsp;&nbsp;/<Link to={'/auth/findp'}>비밀번호 찾기</Link>
            </div>
            <div className={'link'}>
                이미 계정이 있으시다면?<Link to={'/login'}>로그인</Link>
            </div>
            <PopupWarn
                visible={alertVisible}
                hiding={alertHide}
                confirm={alertHide}
                message={'현재 개발 중입니다. 조금만 기다려 주세요!'}
                closeHide
            />
        </div>
    )
}