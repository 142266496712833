import React from "react"
import { fwUtil } from "../../util";

const ColorSelctor = (props) => {
    const { title, selcted, colors, setColor } = props;

    if (fwUtil.conv.check.nua(colors)) {
        return;
    }

    return (
        <div className={'fw-color-selctor'}>
            <div className={'fw-color-selctor-label'}>{title}</div>
            <div className={'fw-color-selctor-body'}>
                {colors.map((item, index) => (
                    <div 
                        key={index} 
                        className={`fw-colors-item ${selcted === item.name ? 'sel' : ''}`} 
                        title={item.colo_name} 
                        style={{ backgroundColor: item.colo_code }}
                        onClick={() => setColor(item.colo_name)}
                    ></div>
                ))}
            </div>
        </div>           
    )
};

export {
    ColorSelctor
}