import React, { useCallback, useRef, useState } from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, { GroupItem } from 'devextreme-react/form';
import { FileManager, TextBox } from 'devextreme-react';
import { Column, ContextMenu, Details, FileSelectionItem, Item, ItemView, Permissions, Toolbar } from 'devextreme-react/cjs/file-manager';
import { Button, Popup, ScrollView, ValidationGroup } from 'devextreme-react';
import { PopupToolbar } from './popup-item';
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { FormPass } from '../form/form-items';
import { getAlertData } from './popup-util';
import { fwUtil } from '../../util';
import './popup.scss';

// 알림 다이얼로그
const PopupAler = (props) => {
    const {
        grid,
        visible, hiding, confirm,
        message,
    } = props;

    const [alertData, setAlertData] = useState([]);

    const onShowing = () => {
        if (grid) {
            const selectedData = fwUtil.grid.get.sKey(grid);
            if (selectedData) {
                setAlertData(getAlertData(selectedData));
            } else {
                setAlertData([]);
                hiding();
            }
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-alert-popup' }}
            onShowing={onShowing}
            hideOnOutsideClick
        >
            <div className="fw-popup-content fw-column-flex-box">
                {alertData && alertData.map((item, index) => (
                    <span className="alert-key" key={index}>
                        {typeof item === 'object'
                            ? Object.values(item).map((v, i) => <span key={i}>{v} </span>)
                            : item
                        }
                    </span>
                ))}
                <span className="alert-message">{message}</span>
                <div className="alert-button-wrapper fw-row-flex-box">
                    <Button
                        text="확인"
                        stylingMode="contained"
                        type='default'
                        onClick={confirm}
                    />
                    <Button
                        text="취소"
                        className="fw-cancle-button"
                        stylingMode="contained"
                        type='normal'
                        onClick={hiding}
                    />
                </div>
            </div>
        </Popup>
    );
};

// 확인 다이얼로그
const PopupConf = (props) => {
    const {
        info, confirmText, placeholder, title,
        visible, hiding, confirm, closeHide
    } = props;

    const confirmBtnText = confirmText || "확인";
    const hideOnOutsideClick = fwUtil.conv.tern(true, !closeHide);
    const [conf, setConf] = useState("");
    const confChange = useCallback((e) => { setConf(e) }, []);

    const onConfirmClick = useCallback(() => {
        const confCheck = conf === confirmText;
        if (confCheck) {
            confirm();
        } else {
            fwUtil.ui.notifyMes.erro(placeholder);
        }
    }, [conf, confirmText, confirm, placeholder]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            title={title}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-confirm-popup' }}
            hideOnOutsideClick={hideOnOutsideClick}
        >
            <div className="fw-popup-content fw-column-flex-box">
                <div className="info-message">{info}</div>
                <div className='confirm-input-wrapper'>
                    <TextBox
                        value={conf}
                        onValueChange={confChange}
                        placeholder={placeholder}
                        stylingMode='outlined'
                    />
                </div>
                <div className="confirm-button-wrapper fw-row-flex-box">
                    <Button
                        text={confirmBtnText}
                        stylingMode="contained"
                        type='default'
                        onClick={onConfirmClick}
                    />
                    {!closeHide &&
                        <Button
                            text="취소"
                            className="fw-cancle-button"
                            stylingMode="outlined"
                            type='default'
                            onClick={hiding}
                        />
                    }
                </div>
            </div>
        </Popup>
    );
};

// 경고 다이얼로그
const PopupWarn = (props) => {
    const {
        warning, loading,
        visible, hiding, confirm, confirmText,
        message, closeHide
    } = props;

    const confirmBtnText = confirmText || "확인";
    const hideOnOutsideClick = fwUtil.conv.tern(true, !closeHide);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-warning-popup' }}
            hideOnOutsideClick={hideOnOutsideClick}
        >
            <div className="fw-popup-content fw-column-flex-box">
                <span className="warning-key">
                    {warning}
                </span>
                <span className="warning-message">{message}</span>
                <div className="warning-button-wrapper fw-row-flex-box">
                    <Button
                        onClick={confirm}
                        type='default'
                        stylingMode="contained"
                        disabled={loading}
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'22px'} height={'22px'} visible={true} />
                                    : confirmBtnText
                            }
                        </span>
                    </Button>
                    {!closeHide &&
                        <Button
                            className="fw-cancle-button"
                            type='normal'
                            stylingMode="contained"
                            onClick={hiding}
                            disabled={loading}
                        >
                            <span className="dx-button-text">
                                {
                                    loading
                                        ? <LoadIndicator width={'22px'} height={'22px'} visible={true} />
                                        : "취소"
                                }
                            </span>
                        </Button>
                    }
                </div>
            </div>
        </Popup>
    );
};

// 주소 다이얼로그
const PopupAddr = (props) => {
    const {
        visible, hiding, confirm
    } = props;
    const oncomplete = (data) => {
        hiding();
        confirm(data);
    };

    if (!visible) {
        return;
    }

    return (
        <>
            <Popup
                title='주소찾기'
                visible={visible}
                onHiding={hiding}
                width={'auto'}
                height={'auto'}
                showCloseButton={true}
                wrapperAttr={{ class: 'fw-daum-popup' }}
                hideOnOutsideClick
            >
                <div className="fw-popup-content">
                    <DaumPostcodeEmbed
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        onComplete={oncomplete}
                    />
                </div>
            </Popup>
        </>
    );
};


// 비밀번호 변경 다이얼로그
const PopupPass = (props) => {
    const {
        data, updateField,
        visible, hiding, isXSmall
    } = props;

    const changeApi = '/privat/user/sys/pass/change'
    const passRegx = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|;:',.<>?/~`"]).{8,20}$/;
    const passPlaceholder = "비밀번호(영문자, 숫자, 특수문자 필수 포함 8~20자리)";

    const onSubmit = useCallback(async() => {
        //비밀번호 변경 로직
        const postData = {
            currPass: data.pwd_curr,
            passWord: data.pwd,
        };

        const res = await fwUtil.apis.return(changeApi, postData);

        if (res?.status) {
            fwUtil.aler.toast.info(res.message);
            hiding();
        } else {
            fwUtil.aler.toast.erro(res?.message || '비밀번호 변경에 실패하였습니다.')
        }
    }, [data, hiding]);

    // 동작 버튼 클릭
    const confirm = useCallback(({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <Popup
            title='비밀번호 변경'
            visible={visible}
            onHiding={hiding}
            width={isXSmall ? 350 : 400}
            height={'auto'}
            maxWidth={'100%'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-pass-popup' }}
            hideOnOutsideClick
        >
            <div className="fw-popup-content fw-column-flex-box">
                <ValidationGroup>
                    <Form>
                        <GroupItem cssClass='fw-current-password-wrapper'>
                            {FormPass({ value: data.pwd_curr, onValueChange: updateField('pwd_curr'), label: '현재 비밀번호', required: true, hideMarker: true })}
                        </GroupItem>
                        {FormPass({ value: data.pwd, onValueChange: updateField('pwd'), label: '비밀번호', rule: passRegx, required: true, hideMarker: true, placeholder: passPlaceholder })}
                        {FormPass({ value: data.pwd_conf, onValueChange: updateField('pwd_conf'), label: '비밀번호 확인', rule: passRegx, required: true, hideMarker: true, placeholder: '비밀번호 확인', confirm: true, password: data.pwd })}
                    </Form>
                </ValidationGroup>
                <PopupToolbar
                    type={'수정'}
                    onSubmit={confirm}
                    onClose={hiding}
                />
            </div>
        </Popup>
    );
};

const PopupForm = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, additionalChild
    } = props;

    const ValidationRef = useRef(null);
    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'95%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup ref={ValidationRef} >
                    <div className={'fw-popup-content fw-form-popup fw-column-flex-box'}>
                        <div className={`fw-popup-form fw-column-flex-box`}>
                            <form>
                                <Form>
                                    {children}
                                </Form>
                            </form>
                            <div className={'fw-additional-child-wrapper'}>
                                {additionalChild}
                            </div>
                        </div>
                        {onSubmit &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

const PopupGrid = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children,
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup>
                    <div className={'fw-popup-content fw-grid-popup fw-column-flex-box'}>
                        {children}
                        {type &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

const PopupFile = (props) => {
    const {
        title, width, loading, setLoading,
        visible, hiding, showing, shown,
        outSideClick, closeButton, renderDefer,
        fileManagerRef, fileSystemProvider, allowedFileExtensions, rootFileName,
        onFileUploading, onItemDeleting, onItemRenaming
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(false, renderDefer);

    const onShown = useCallback(async () => {
        shown && shown();
        await fileManagerRef?.current?.instance?.repaint();
        await setLoading(false);
    }, [shown, fileManagerRef, setLoading]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={onShown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible />
                </div>
            }
            <div className='fw-file-manager-wrapper'>
                <FileManager
                    ref={fileManagerRef}
                    fileSystemProvider={fileSystemProvider}
                    allowedFileExtensions={allowedFileExtensions}
                    rootFolderName={rootFileName}
                    onFileUploading={onFileUploading}
                    onItemDeleting={onItemDeleting}
                    onItemRenaming={onItemRenaming}
                >
                    <Permissions
                        delete
                        rename
                        upload
                        download
                    />
                    <ItemView showParentFolder={false}>
                        <Details>
                            <Column dataField="thumbnail" />
                            <Column dataField="name" caption='파일명' />
                            <Column dataField="dateModified" caption='수정일자' />
                            <Column dataField="size" caption='크기' />
                        </Details>
                    </ItemView>
                    <Toolbar>
                        <Item name="showNavPane" visible />
                        <Item name="separator" />
                        <Item name="upload" text='업로드' options={{ hint: "Shift 또는 Ctrl로 다중선택" }} />
                        <Item name="refresh" options={{ hint: "새로고침" }} />
                        <Item
                            name="separator"
                            location="after"
                        />
                        <Item name="switchView" />
                        <FileSelectionItem name="rename" text='이름변경' />
                        <FileSelectionItem name="separator" />
                        <FileSelectionItem name="delete" text='삭제' />
                        <FileSelectionItem name="separator" />
                        <FileSelectionItem name="download" text='다운로드' />
                        <FileSelectionItem name="clearSelection" text='선택헤제' />
                    </Toolbar>
                    <ContextMenu>
                        <Item name="rename" text='이름변경' beginGroup />
                        <Item name="delete" text='삭제' />
                        <Item name="download" text='다운로드' />
                    </ContextMenu>
                </FileManager>
            </div>
        </Popup>
    )
};

const PopupTerm = (props) => {
    const {
        title, width, height, loading,
        visible, hiding, showing, shown,
        outSideClick, closeButton, renderDefer,
        children
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={height}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <div className='popup-term-container'>
                {children}
            </div>
        </Popup>
    )
};

const PopupCard = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, cardContent, colorSelctor,
    } = props;

    const ValidationRef = useRef(null);
    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'95%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup ref={ValidationRef} >
                    <div className={'fw-popup-content fw-form-popup fw-card-popup fw-column-flex-box'}>
                        <div className={`fw-popup-form fw-column-flex-box`}>
                            <div className={`fw-popup-form-card-content-wrapper`}>
                                {cardContent}
                            </div>
                            <form>
                                <Form>
                                    {children}
                                </Form>
                            </form>
                            {colorSelctor}
                        </div>
                        {onSubmit &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

export {
    PopupAler,
    PopupConf,
    PopupWarn,
    PopupAddr,
    PopupPass,
    PopupForm,
    PopupGrid,
    PopupFile,
    PopupTerm,
    PopupCard,
};