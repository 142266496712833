import { AccountPanel } from "../../../script/components";
import { intrComp, suppComp } from "../../../../utils/default-comp";
import { ContentHeader } from "../../../script/components/header/Header";
import FormJoins from "../../../script/components/account-panel/FormJoins";
import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";

export default function AuthJoins() {
    const { user } = useAuth();
    const navigate = useNavigate();

    if (user) { navigate('/home') }

    return (
        <>
            <ContentHeader
                root={'home'}
                rootLink={'/home'}
                path={['회원서비스', '회원가입']}
            />
            <AccountPanel title="회원가입" intrComp={intrComp} suppComp={suppComp} >
                <FormJoins />
            </AccountPanel>
        </>
    )
}