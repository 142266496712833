import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';
import { fwUtil } from '../focuswin/script/util';
import { useNavigate } from 'react-router-dom';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // 초기 메뉴 조회
  useEffect(() => {
    (async function () {
      const userResult = await getUser();
      if (userResult.isOk) {
        setUser(userResult.data);
      };
      setLoading(false);
    })();
  }, []);

  // 로그인
  const signIn = useCallback(async (userIdno, passWord) => {
    const result = await sendSignInRequest(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      fwUtil.aler.toast.info(`${result.data.userIdno}님 반갑습니다.`);
    }
    return result;
  }, []);

  // 로그아웃
  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result.isOk) {
      setUser(undefined);
      window.location.reload();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setLoading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
