import { AccountPanel } from "../../../script/components";
import { intrComp, suppComp } from "../../../../utils/default-comp";
import { ContentHeader } from "../../../script/components/header/Header";
import FormLogin from "../../../script/components/account-panel/FormLogin";
import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";

export default function AuthLogin() {
    const { user } = useAuth();
    const navigate = useNavigate();

    if (user) { navigate('/home') }

    return (
        <>
            <ContentHeader
                root={'home'}
                rootLink={'/home'}
                path={['회원서비스', '로그인']}
            />
            <AccountPanel title="로그인" detail="아이디와 비밀번호를 입력해 주세요." intrComp={intrComp} suppComp={suppComp} >
                <FormLogin />
            </AccountPanel>
        </>
    )
}