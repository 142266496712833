import React, { useCallback, useEffect, useState } from "react";
import { ContentHeader } from "../../../script/components/header/Header";
import { CustomTabPanel } from "../../../script/components/tab-panel/tab-panel-cust/panel-cust";
import { Button, DateRangeBox, LoadPanel, PieChart, Toolbar } from "devextreme-react";
import { Animation, Hide, Show } from "devextreme-react/cjs/load-panel";
import { Item as ToolbarItem } from "devextreme-react/cjs/toolbar";
import { fwUtil } from "../../../script/util";
import { Connector, Format, Label, Legend, Series, Tooltip } from "devextreme-react/cjs/pie-chart";
import { PagingGrid } from "../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../script/components/data-grid/data-grid-column/grid-column";
import { convertCharData, convertSummData } from "./UserUsagStat-util";
import { useNavigate } from "react-router-dom";
import './user.scss'

const userNavData = [
    { icon: 'user', name: '내 정보', link: '/user/infostat' },
    { icon: 'datatrending', name: '사용량 조회', link: '/user/usagstat' },
];

function customizeTooltip(arg) {
    console.log(arg)
    return {
        text: `${arg.argumentText} : ${fwUtil.conv.to.krLocales(arg?.value) || 0} 원`,
    };
}

function customizeLabel(arg) {
    return `${fwUtil.conv.to.krLocales(arg?.value) || 0} 원`;
}

function getUsePowerKwh(data, suffix) {
    if (!data || !data.usePower) {
        return `0 ${suffix}`;
    }

    const usePower = Number(data.usePower);
    if (isNaN(usePower)) {
        return `0 ${suffix}`;
    }

    return `${(usePower / 1000)} ${suffix}`;
}

export default function UserUsagstat() {
    // api
    const usagSearchApi = '/privat/user/sys/usag/status/search';
    // 시스템
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    // 데이터
    const [usagData, setUsagData] = useState([]);
    const [charData, setCharData] = useState([]);
    const [summData, setSummData] = useState([]);  
    const usagDataUpdate = useCallback((e) => setUsagData(e), []);
    const charDataUpdate = useCallback((e) => setCharData(e), []);
    const summDataUpdate = useCallback((e) => setSummData(e), []);  
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        setLoading(true);
        if (dateValue && dateValue[0] && dateValue[1]) {
            
            const sendData = { start: dateValue[0], compl: dateValue[1] };
            const res = await fwUtil.apis.return(usagSearchApi, sendData);
            if (res?.status) {
                const resData = res.dataSet;
                const convCharData = convertCharData(resData);
                const convSummData = convertSummData(resData);
                usagDataUpdate(resData);
                charDataUpdate(convCharData);
                summDataUpdate(convSummData);
            } else {
                navigate('/home');
                fwUtil.aler.toast.erro('잘못된 접근입니다');
                window.location.reload();
                return;
            }
        }
        setLoading(false);
    }, [dateValue, usagSearchApi, usagDataUpdate, charDataUpdate, summDataUpdate, navigate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    return (
        <>
            <LoadPanel visible={loading} shadingColor='rgba(0,0,0,.4)'>
                <Animation>
                    <Hide type='fadeOut' delay={200} />
                    <Show type='fadeIn' delay={200} />
                </Animation>
            </LoadPanel>
            <ContentHeader
                root={'user'}
                rootLink={'/user/infostat'}
                path={['고객서비스', '사용량 조회']}
            />
            <div id={'fw-page-content'} className={'usage user-page'}>
                <CustomTabPanel data={userNavData} />
                <div className={'fw-usage-container fw-column-flex-box'}>
                    <div className={'fw-usage-header'}>
                        <Toolbar>
                            <ToolbarItem location='before' locateInMenu='auto' cssClass='usage-toolbar-item date-range'>
                                <div className='date-range-wrapper fw-flex-box row'>
                                    <div className='date-range-title'>조회 날짜 설정</div>
                                    <DateRangeBox
                                        width={270}
                                        value={dateValue}
                                        onValueChanged={dateValueChange}
                                        acceptCustomValue={false}
                                        labelMode='hidden'
                                        stylingMode='outlined'
                                        displayFormat={'yyyy-MM-dd'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                    />
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location='before' locateInMenu='auto' cssClass={'fw-dash-toolbar-search-item'}>
                                <Button
                                    icon={'search'}
                                    text={"조회"}
                                    type='normal'
                                    stylingMode='contained'
                                    onClick={handleSearch}
                                    elementAttr={{ title: "사용량 조회" }}
                                />
                            </ToolbarItem>
                        </Toolbar>
                    </div>
                    <div className={'fw-usage-body'}>
                        <div className={'fw-usage-summary'}>
                            <div className={'fw-usage-chart'}>
                                <PieChart
                                    type="doughnut"
                                    palette={"Soft Pastel"}
                                    dataSource={charData}
                                    width={'25vw'}
                                    height={'25vw'}
                                >
                                    <Series argumentField="station_name" valueField="resultPrice" >
                                        <Label visible={true} customizeText={(arg) => customizeLabel(arg)}>
                                            <Connector visible={true} />
                                        </Label>
                                    </Series>
                                    <Legend margin={0} horizontalAlignment="center" verticalAlignment="bottom" />
                                    <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                                        <Format type="millions" />
                                    </Tooltip>
                                </PieChart>
                            </div>
                            <div className={'fw-usage-message'}>
                                <div className={'fw-message-guide'}>{`${dateValue[0] || '0000-00-00'} ~ ${dateValue[1] || '0000-00-00'} 기준`}</div>
                                <div className={'fw-message-body'}>
                                    <div className={'fw-message-power detail'}><span>사용량</span><strong>{`${getUsePowerKwh(summData, '') || '-'}`} <span>kWh</span></strong> </div>
                                    <div className={'fw-message-price-use detail'}><span>사용금액</span><strong>{`${summData.resultPrice || '-'}`} <span>원</span></strong></div>
                                </div>
                                <Button
                                    className={'fw-payment-btns'}
                                    text={'결제하기'}
                                    stylingMode="contained"
                                    type="normal"
                                    onClick={() => fwUtil.aler.toast.info('결제 모듈 연동이 필요합니다!')}
                                />
                            </div>
                        </div>
                        <div className={'fw-usage-detail'}>
                            <PagingGrid
                                title={"사용량 상세"}
                                gridSize={'single'}
                                gridDataSource={usagData}
                                selection={'single'}
                                toolbar={{ default: false }}
                                height={350}
                            >
                                {CommCol({ dataField: "station_name", caption: "충전소" })}
                                {CommCol({ dataField: "startTransaction", caption: "충전시작 시간" })}
                                {CommCol({ dataField: "stopTransaction", caption: "충전종료 시간" })}
                                {CommCol({ dataField: "tran_dura", caption: "충전시간" })}
                                {CommCol({ dataField: "usePower", caption: "충전량", displayExpr: (e) => getUsePowerKwh(e, 'kWh'), alig: 'right' })}
                                {DeciCol({ dataField: "resultPrice", caption: "충전금액", suffix: "원" })}
                            </PagingGrid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}