import React, { useState, useCallback } from 'react';
import Form from 'devextreme-react/form';
import { useAuth } from '../../../../contexts/auth';
import { FormPass, FormText } from '../form/form-items';
import { ValidationGroup } from 'devextreme-react';
import { Link, useNavigate } from 'react-router-dom';
import { AccountFormButton } from './account-panel-item';
import { fwUtil } from '../../util';

const defaultData = {
  user_idno: '',
  pass_word: '',
};

export default function FormLogin() {
  // 시스템
  const { signIn } = useAuth();
  const navigate = useNavigate();
  // 페이지 상태값
  const [loading, setLoading] = useState(false);
  // 데이터
  const [data, setData] = useState(defaultData);
  const dataUpdate = useCallback((e) => setData(e), [setData]);

  // 로그인
  const onSubmit = useCallback(async () => {
    const postData = {
      userIdno: data.user_idno,
      passWord: data.pass_word,
    }
    setLoading(true);

    const result = await signIn(postData.userIdno, postData.passWord);
    if (!result.isOk) {
      fwUtil.aler.toast.erro(result.message);
      setLoading(false);
    } else {
      navigate('/home');
    }
  }, [signIn, data, navigate]);

  // 로그인 버튼 클릭
  const handleLogin = useCallback((e) => {
    e.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const onCreateAccountClick = useCallback(() => {
    navigate('/auth/joins');
  }, [navigate]);

  // 수정
  const updateField = (field) => (value) => {
    if (!data) return;

    dataUpdate({ ...data, [field]: value });
  };

  return (
    <div id={'login-form'} className={'account-form'}>
      <ValidationGroup>
        <form onSubmit={handleLogin}>
          <Form className='account-panel-form'>
            {FormText({ value: data.user_idno, onValueChange: updateField('user_idno'), label: '아이디', required: true, hideMarker: true, placeholder: '아이디' })}
            {FormPass({ value: data.pass_word, onValueChange: updateField('pass_word'), label: '비밀번호', required: true, hideMarker: true, placeholder: '비밀번호' })}
          </Form>
          <AccountFormButton 
            loading={loading}
            submitBtn={true}
            className={'first-btn'}
            text={'로그인'}
            type={'default'}
          />          
          <AccountFormButton 
            className={'account-btn'}
            text={'회원가입'}
            type={'normal'}
            onClick={onCreateAccountClick}
          />
          <div className={'link'}>
            계정이 기억나지 않으신가요?<Link to={'/auth/findi'}>아이디 찾기</Link>&nbsp;&nbsp;/<Link to={'/auth/findp'}>비밀번호 찾기</Link>
          </div>         
        </form>
      </ValidationGroup>
    </div>
  );
};