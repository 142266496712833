// 사이드바 네비게이션 트리에 사용되는 라우터 경로
export function CreateTreeNavigation() {
  const menuData = [
    { path: '/home', text: '메인화면', icon: 'home' },
    {
      text: '회사소개',
      icon: 'car',
      items: [
        { path: '/comp/ceosmess', text: 'CEO 인사말' },
        { path: '/comp/business', text: '사업소개' },
        { path: '/comp/historys', text: '연혁' },
        { path: '/comp/recruits', text: '인재채용' },
      ]
    },
    {
      text: '충전인프라',
      icon: 'datapie',
      items: [
        { path: '/chrg/infointr', text: '충전기 소개' },
        { path: '/chrg/infostat', text: '충전소 찾기' },
        { path: '/chrg/paysstat', text: '요금제' },
        { path: '/chrg/instinqu', text: '충전기 설치 문의' },
      ]
    },
    {
      text: '게시판',
      icon: 'paste',
      items: [
        { path: '/boar/notistat', text: '공지사항' },
        { path: '/boar/newsstat', text: '뉴스' },
        { path: '/boar/qnasstat', text: 'Q&A' },
        { path: '/boar/aserinqu', text: 'A/S 신청' },
      ]
    },
  ];

  return menuData;
};