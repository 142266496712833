import React from 'react';
import intrLogo from '../../../assets/intr-logo.png';
import './account-panel.scss';

export default function AccountPanel(props) {
  const { intrComp, title, detail, children } = props;

  return (
    <div className='account-container'>
      <div className='accont-card'>
        <div className='card-header'>
          <img src={intrLogo} alt={`${intrComp.name} 로고 이미지`} />
          <div className='card-body-header-title'>{title}</div>
          <div className='card-body-header-detail'>{detail}</div>       
        </div>
        <div className='card-body'>
          <div className="card-body-wave"></div>
          <div className="card-body-wave"></div>
          {children}
        </div>
      </div>
    </div>
)}
