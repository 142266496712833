import React, { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { ThemeProvider, useTheme } from './focuswin/script/contexts/theme';
import { useScreenSizeClass } from './utils/media-query';
import LoadPanel from 'devextreme-react/load-panel';
import CmsContent from './Content-cms';
import './dx-styles.scss';
import './focuswin/style/focuswin.variables.css';
import './focuswin/style/focuswin.theme.base.scss';
import './focuswin/style/focuswin.theme.icon.scss';

// 한글 딕셔너리 추가
import koMessages from "./ko.json";
import { locale, loadMessages } from "devextreme/localization";

// 라이센스
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
config({ licenseKey });

// 언어 설정
loadMessages(koMessages);
locale("ko");

// App 컴포넌트 정의
function App() {
  const { loading } = useAuth();
  const { setTheme, getTheme } = useTheme();

  useEffect(() => setTheme(getTheme()), [setTheme, getTheme]);

  return (
    <>
      {loading ? (
        <LoadPanel visible={true} />
      ) : (
        <CmsContent />
      )}
    </>
  );
};

// Root 컴포넌트 정의
export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}
