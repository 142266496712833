const cardColorList = [
    { colo_name: 'blue', colo_code: 'rgb(104, 170, 255)' },
    { colo_name: 'green', colo_code: 'rgb(144, 238, 144)' },
    { colo_name: 'cyan', colo_code: 'rgb(48, 146, 170)' },
    { colo_name: 'purple', colo_code: 'rgb(186, 85, 211)' },
    { colo_name: 'orange', colo_code: 'rgb(255, 165, 0)' },
    { colo_name: 'pink', colo_code: 'rgb(255, 182, 193)' },
    { colo_name: 'black', colo_code: 'rgb(54, 54, 54)' },
];

export const colorList = {
    card: cardColorList,
}