const defaultSummary = [{ station_id: "충전기록 없음", station_name: "충전기록 없음", resultPrice: 0, tran_dura: 0, usePower: 0, }]

export function convertCharData(dataSet) {
    const stationSummary = [];

    if (!dataSet || dataSet.length === 0) {
        return defaultSummary;
    }

    dataSet.forEach(data => {
        const stationId = data.station_id;

        let stationData = stationSummary.find(item => item.station_id === stationId);

        if (!stationData) {
            stationSummary.push({
                station_id: stationId,
                station_name: data.station_name,
                resultPrice: 0,
                usePower: 0,
                tran_dura: 0
            });
            stationData = stationSummary[stationSummary.length - 1];
        }

        stationData.resultPrice += data.resultPrice;
        stationData.usePower += data.usePower;
        stationData.tran_dura += data.tran_dura;
    });

    return stationSummary;
};

export function convertSummData(dataSet) {
    const totalSummary = {
        resultPrice: 0,
        usePower: 0,
        tran_dura: 0
    };

    if (!dataSet || dataSet.length === 0) {
        return totalSummary;
    }

    dataSet.forEach(data => {
        totalSummary.resultPrice += data.resultPrice;
        totalSummary.usePower += data.usePower;
        totalSummary.tran_dura += data.tran_dura;
    });

    return totalSummary;
}
