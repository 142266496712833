import { AccountPanel } from "../../../script/components";
import { intrComp, suppComp } from "../../../../utils/default-comp";
import { ContentHeader } from "../../../script/components/header/Header";
import FormFindP from "../../../script/components/account-panel/FormFindP";
import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";

export default function AuthFindP() {
    const { user } = useAuth();
    const navigate = useNavigate();

    if (user) { navigate('/home') }

    return (
        <>
            <ContentHeader
                root={'home'}
                rootLink={'/home'}
                path={['회원서비스', '비밀번호 찾기']}
            />
            <AccountPanel title="비밀번호 찾기" detail="찾으시려는 계정의 아이디와 메일을 입력해주세요" intrComp={intrComp} suppComp={suppComp} >
                <FormFindP />
            </AccountPanel>
        </>
    )
}