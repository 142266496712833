import { useCallback, useEffect, useState } from 'react';
import DataGrid, { LoadPanel as GridPanel, Pager, Paging, Scrolling, Sorting, Selection, HeaderFilter, Editing, Toolbar, Item, SearchPanel, ColumnChooser, Export, GroupPanel, Grouping } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import { GridToolbar } from '../../toolbar/toolbar';
import { LoadPanel } from 'devextreme-react';
import { fwUtil } from '../../../util';
import './grid-body.scss';

const defaultExportFormat = ['xlsx'];

const headerHeight = 60;
const footerHeight = 74;
const contentHeaderHeight = 57;
const gridMargin = 30;
const gridRowHeight = 37;
const gridHeaderHeight = 43;
const gridHeaderColHeight = 40.5;
const gridPagerHeight = 56;

const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
        if (item.name === "columnChooserButton" && item.options) {
            item.options.text = "열선택기"
            item.showText = "always";
            item.options.stylingMode = 'contained'
        }
        if (item.name === "exportButton" && item.options) {
            item.options.text = "엑셀"
            item.showText = "always";
            item.options.stylingMode = 'contained'
        }
    })
}

const PagingGrid = (props) => {
    const {
        title, width, height,
        mainKey, gridRef, gridDataSource,
        onRowClick, onRowDblClick,
        dateValue, dateValueChange, duraYesn, duraYesnChange,
        edit, toolbar, ins, upd, del, exp, search,
        selection, children,
        dispose, grouping,
    } = props;

    const fileName = title ? `${title}` : '현황';
    const searchVisible = fwUtil.conv.tern(true, search);
    const exportFormat = exp && fwUtil.conv.tern(exp.formats, defaultExportFormat);

    const handleRowDblClick = useCallback(() => {
        upd && upd.onClick();
        onRowDblClick && onRowDblClick();
    }, [upd, onRowDblClick]);

    if (dispose) {
        return null;
    }

    return (
        <>
            <DataGrid
                className={`fw-page-grid`}
                width={width || '100%'}
                height={height}
                keyExpr={mainKey}
                ref={gridRef}
                dataSource={gridDataSource}
                onRowClick={onRowClick}
                onRowDblClick={handleRowDblClick}
                onExporting={fwUtil.data.export(fileName)}
                onToolbarPreparing={onToolbarPreparing}
                noDataText='표시할 정보가 없습니다'
                focusedRowEnabled={selection === 'multiple' ? true : false}
                showBorders
                columnAutoWidth
                allowColumnReordering
                allowColumnResizing
                showColumnLines
                showRowLines
                rowAlternationEnabled
            >
                <GridPanel enabled />
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Scrolling columnRenderingMode='standard' />
                <Paging enabled defaultPageSize={5} onPageIndexChange={(e) => handlePageChanged(e, gridRef)} />
                <Pager showInfo showNavigationButtons={false} showPageSizeSelector={false} />
                <Selection {...getSelectionPagingProps(selection)} />
                {grouping && <GroupPanel visible />}
                {grouping && <Grouping autoExpandAll />}
                {toolbar && !toolbar.titleOnly && <SearchPanel visible={searchVisible} placeholder='검색' width={200} />}
                {exp && <Export enabled allowExportSelectedData={selection === "multiple"} formats={exportFormat} />}
                {toolbar && GridToolbar({
                    title: title,
                    dateValue: dateValue,
                    dateValueChange: dateValueChange,
                    duraYesn: duraYesn,
                    duraYesnChange: duraYesnChange,
                    ins: ins,
                    upd: upd,
                    del: del,
                    exp: exp,
                    defaultItems: toolbar.default,
                    toolbar: toolbar,
                })}
                {children}
            </DataGrid>
            <div className={'fw-fake-pager'} />
        </>
    );
};

const ScrollGrid = (props) => {
    const {
        title, role, additonTitle, width, height, className,
        mainKey, gridRef, gridDataSource,
        onRowClick, onRowDblClick,
        dateValue, dateValueChange, duraYesn, duraYesnChange,
        edit, toolbar, ins, upd, del, exp, search,
        selection, children,
        dispose, alter, loadPanel, hideColumnChooser,
        loadHide
    } = props;

    const fileName = title ? `${title}` : '현황';
    const mainTitle = title && `${title}${role ? " " + role : ""}${additonTitle ? " " + additonTitle : ""}`;
    const classNames = `fw-scroll-grid ${className ? className : ''}`
    const rowAlter = fwUtil.conv.tern(true, alter);
    const searchVisible = fwUtil.conv.tern(false, search);
    const loadPanelVisble = fwUtil.conv.tern(true, loadPanel);
    const exportFormat = exp && fwUtil.conv.tern(exp.formats, defaultExportFormat);

    const handleRowDblClick = useCallback((e) => {
        upd && upd.onClick();
        onRowDblClick && onRowDblClick(e);
    }, [upd, onRowDblClick]);

    if (dispose) {
        return null;
    }

    return (
        <DataGrid
            className={classNames}
            width={width || '100%'}
            height={height}
            keyExpr={mainKey}
            ref={gridRef}
            dataSource={gridDataSource}
            onRowClick={onRowClick}
            onRowDblClick={handleRowDblClick}
            onExporting={fwUtil.data.export(fileName)}
            onToolbarPreparing={onToolbarPreparing}
            noDataText='표시할 정보가 없습니다'
            focusedRowEnabled={selection ? true : false}
            rowAlternationEnabled={rowAlter}
            loadPanel={!loadHide}
            showBorders
            columnAutoWidth
            allowColumnReordering
            allowColumnResizing
            showColumnLines
            showRowLines
            repaintChangesOnly
            renderAsync
        >
            <GridPanel enabled={loadPanelVisble} />
            <HeaderFilter visible />
            <Sorting mode='multiple' />
            <Scrolling columnRenderingMode='standard' mode='virtual' renderAsync={false} preloadEnabled />
            <Paging enabled={false} />
            <Selection {...getSelectionScrollProps(selection)} />
            {toolbar && !toolbar.titleOnly && !hideColumnChooser && <ColumnChooser enabled />}
            {toolbar && !toolbar.titleOnly && <SearchPanel visible={searchVisible} placeholder='검색' />}
            {exp && <Export enabled allowExportSelectedData={selection === "multiple"} formats={exportFormat} />}
            {edit && <Editing mode="cell" allowUpdating />}
            {toolbar && GridToolbar({
                title: mainTitle,
                dateValue: dateValue,
                dateValueChange: dateValueChange,
                duraYesn: duraYesn,
                duraYesnChange: duraYesnChange,
                ins: ins,
                upd: upd,
                del: del,
                exp: exp,
                defaultItems: toolbar.default,
                toolbar: toolbar,
            })}
            {children}
        </DataGrid>
    )
};

const AddingGrid = (props) => {
    const {
        title, width, height,
        gridRef, gridDataSource,
        allowEdit, allowInsert, allowUpdate, allowDelete, additionTool,
        children
    } = props;
    const gridHeight = fwUtil.conv.tern(266, height);

    return (
        <DataGrid
            ref={gridRef}
            dataSource={gridDataSource}
            width={width}
            height={gridHeight}
            showBorders
            showColumnLines
            columnAutoWidth
            allowColumnResizing
            allowColumnReordering
        >
            <Paging enabled={false} />
            {allowEdit &&
                <Editing
                    mode="cell"
                    startEditAction={'click'}
                    allowAdding={allowInsert}
                    allowUpdating={allowUpdate}
                    allowDeleting={allowDelete}
                    confirmDelete={false}
                    selectTextOnEditStart
                />
            }
            <Scrolling mode='standard' columnRenderingMode="standard" />
            <Toolbar>
                <Item location='before'>
                    <span className='fw-additional-label'>{title}</span>
                </Item>
                <Item location='after'>
                    {allowInsert &&
                        <Button
                            icon={'add'}
                            onClick={() => gridDataSource.store().insert({}).then(() => gridDataSource.reload())}
                        />
                    }
                    {additionTool && additionTool}
                </Item>
            </Toolbar>
            {children}
        </DataGrid>
    );
}

export {
    PagingGrid,
    ScrollGrid,
    AddingGrid,
}

const getSelectionPagingProps = (selection) => {
    switch (selection) {
        case 'single':
            return { showCheckBoxesMode: 'none', mode: 'single' };
        case 'multiple':
            return { selectAllMode: 'page', showCheckBoxesMode: 'always', mode: 'multiple' };
        default:
            return { mode: 'none' };
    }
};

const getSelectionScrollProps = (selection) => {
    switch (selection) {
        case 'single':
            return { showCheckBoxesMode: 'none', mode: 'single' };
        case 'multiple':
            return { selectAllMode: 'allPages', showCheckBoxesMode: 'always', mode: 'multiple' };
        default:
            return { mode: 'none' };
    }
};

const handlePageChanged = (e, gridRef) => {
    if (gridRef?.current) {
        gridRef.current.instance.deselectAll();
    }
};