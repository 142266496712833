function toKoLocales(value) {
    const isNumeric = (val) => !isNaN(parseFloat(val)) && isFinite(val);
    if (isNumeric(value)) {
        return parseFloat(value).toLocaleString('ko-KR', { maximumFractionDigits: 0 });
    } 

    return value;
};

function emptyToNull(value) {
    if (nulCheck(value) || undCheck(value)) {
        return null;
    } else if (strCheck(value)) {
        return value.trim() === "" ? null : value;
    } else if (arrCheck(value)) {
        return value.length === 0 ? null : value;
    } else { return value; }
};

function bufferToBlob(data) {
    if (data && data.type === "Buffer" && data.data) {
        const uint8Array = new Uint8Array(data.data);
        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
        const file = new File([blob], 'filename.jpg', { lastModified: new Date() });
        return file;
    }
    return data;
};

function toConcatNum(contactNumb) {
    const onlyNumbers = contactNumb.replace(/\D/g, '');
    if (onlyNumbers.length === 10) {
        return onlyNumbers.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else if (onlyNumbers.length === 11) {
        return onlyNumbers.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
        return onlyNumbers;
    };
};


export const to = {
    emptToNull: emptyToNull,
    buffToBlob: bufferToBlob,
    concatNum: toConcatNum,
    krLocales: toKoLocales,
};

function nueCheck(value) {
    return (nulCheck(value) || undCheck(value) || value === '') ? true : false;
};

function nubCheck(value) {
    return (nulCheck(value) || undCheck(value) || value.trim() === '') ? true : false;
};

function nunCheck(value) {
    return (nulCheck(value) || undCheck(value) || isNaN(value)) ? true : false;
};

function nuaCheck(value) {
    return (nulCheck(value) || undCheck(value) || (arrCheck(value) && value.length < 1));
}

function strCheck(value) {
    return typeof value === 'string';
};

function numCheck(value) {
    return typeof value === 'number';
};

function objCheck(value) {
    return typeof value === 'object';
};

function arrCheck(value) {
    return Array.isArray(value);
};

function nulCheck(value) {
    return value === null;
};

function undCheck(value) {
    return typeof value === 'undefined';
};

function imgCheck (file) {
    if (!file || !file.name) {
        return false;
    }    
    const extension = file.name.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'gif', 'png'].includes(extension);
    return isImage;
};

export const check = {
    nue: nueCheck,
    nub: nubCheck,
    nun: nunCheck,
    nua: nuaCheck,
    str: strCheck,
    num: numCheck,
    obj: objCheck,
    arr: arrCheck,
    nul: nulCheck,
    und: undCheck,
    img: imgCheck,
}

export function ternFunc(defaultValue, value) {
    if(numCheck(value)) {
        return value;
    } else if(strCheck(value)) {
        return nubCheck(value) ? defaultValue : value
    } else {
        return nueCheck(value) ? defaultValue : value
    }
};

export function makeClass(classMap) {
    return Object.entries(classMap)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(' ');
};




