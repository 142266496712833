import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../utils/media-query';
import { PopupCard } from '../../../script/components/popup/popup';
import { FormBool, FormRegx, FormText } from '../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ColorSelctor } from '../../../script/components/color-selctor/color-selctor';
import { colorList } from '../../../script/components/color-selctor/color-list';
import { PaysCard } from '../../../script/components/pays-card/pays-card';
import { fwUtil } from '../../../script/util';

const defaultData = {
    cred_name : '',
    cred_view : '',
    cred_numb : '0000000000000000',
    cred_colo : 'gray',
    cred_yesn : false,
};

export function UserCardInfoInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);

        const postData = {
            credName : data.cred_name || '',
            credView : data.cred_name || '',
            credNumb : data.cred_numb || '0000000000000000',
            credColo : data.cred_colo || 'gray',
            credYesn : data.cred_yesn ? 1 : 0,            
        };

        const res = await fwUtil.apis.return(insertApi, postData);
        if (res.status) {
            fwUtil.aler.toast.info(res.message);
            hiding();
            refresh();
        } else {
            fwUtil.aler.toast.erro(res?.message || '서버와 연결할 수 없습니다.');
        }
        setLoading(false);
    }, [data, insertApi, hiding, refresh]);

    return (
        <>
        <PopupCard
            title={'결제카드 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 340 : 600 }
            cardContent={
                <PaysCard
                    data={data}
                    edit={false}
                />
            }
            colorSelctor={
                <ColorSelctor 
                    title={'카드색상'}
                    selcted={data?.cred_colo}
                    colors={colorList.card}
                    setColor={(color) => updateField('cred_colo')(color)}
                />
            } 
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormText({ value: data.cred_name, onValueChange: updateField('cred_name'), label: '카드이름', required: true })}
                {FormRegx({ value: data.cred_numb, onValueChange: updateField('cred_numb'), label: '카드번호', required: true, mask: "9999-9999-9999-9999", rule: /^\d{16}$/ })}
                {FormBool({ value: data.cred_yesn, onValueChange: updateField('cred_yesn'), label: '주 사용카드' })}    
            </GroupItem>
        </PopupCard>
        </>
    );
}