import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextBox, Autocomplete, DateBox, FileUploader, NumberBox, SelectBox, CheckBox, TextArea, Button } from 'devextreme-react';
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import { DrawingButton } from '../custom-drawing/custom-drawing';
import { useScreenSize } from '../../../../utils/media-query';
import { Item } from 'devextreme-react/cjs/form';
import { Button as TextButton } from 'devextreme-react/cjs/text-box';
import { fwUtil } from '../../util';
import './form-items.scss';

const allowedImageFileExtensions = ['image/jpg', 'image/jpeg', 'image/png'];
const acceptImageFileExtensions = 'image/jpg, image/jpeg, image/png'
const allowedXlsxFileExtensions = ['xls', 'xlsx'];
const acceptXlsxFileExtensions = '.xls,.xlsx';

const FormExce = (props) => {
    const { value, label, readOnly, onValueChange, disabled, required } = props;

const formLabel = required ? `${label} *` : label;
    const onValueChanged = (e) => {
        if (!e.value || !e.value[0]) return;
        const file = e.value[0];
        const fileName = file.name;
        const parts = fileName.split(".");
        const extension = parts[parts.length - 1];
        if (!allowedXlsxFileExtensions.includes(extension)) {
            fwUtil.ui.notifyMes.erro('파일 형식이 옳바르지 않습니다');
            onValueChange(null);
            return;
        }
        onValueChange(file);
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-exce-item': true, 'accent': required, 'null': !value })} colSpan={2} >
            <div className={'fw-input-wrap'}>
                <FileUploader
                    labelText={formLabel}
                    selectButtonText="찾아보기"
                    uploadMode='useButtons'
                    disabled={readOnly || disabled}
                    accept={acceptXlsxFileExtensions}
                    multiple={false}
                    onValueChanged={onValueChanged}
                />                 
            </div>                    
        </Item>
    );
};

const FormImag = (props) => {
    const { label, labelHide, size, circle, readOnly, value, onValueChange, horizontalLabel, allowDrawing, disabled, required } = props;
    const [image, setImage] = useState('');
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const onDropZoneEnter = useCallback(() => setIsDropZoneActive(true), [setIsDropZoneActive]);
    const onDropZoneLeave = useCallback(() => setIsDropZoneActive(false), [setIsDropZoneActive]);
    const targetRef = useRef(null);
const formLabel = required ? `${label} *` : label;
    // 이미지 변경 후 적용
    useEffect(() => {
        const file = value;
        if (!file || file === '') {
            setIsDropZoneActive(false);
            setImage('');
            return;
        } 
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageDataUrl = e.target.result;
            setImage(imageDataUrl);
            setIsDropZoneActive(false);
        };
        reader.readAsDataURL(file);
    }, [value]);

    const onValueChanged = (e) => {
        if (!e.value || !e.value[0]) return;
        const file = e.value[0];
        const fileExtension = file.type.toLowerCase();
        if (!allowedImageFileExtensions.includes(fileExtension)) {
            fwUtil.ui.notifyMes.erro('파일 형식이 옳바르지 않습니다');
            return;
        }
        onValueChange(file);
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-imag-item': true, 'accent': required, 'circle': circle })}>
            <div className={`form-image-view ${horizontalLabel ? 'fw-row-flex-box' : 'fw-column-flex-box'}`}>
            { label && !labelHide &&
                <span className={horizontalLabel ? 'fw-label fw-horizontal-label' : 'fw-label fw-vertical-label'}>{formLabel}</span>
            }
                <div 
                    className={`form-image ${!disabled && !readOnly ? 'readOnly' : ''}`}
                    style={{
                        width: size,
                        height: size,
                        maxHeight: size,
                    }}
                >
                    <div
                        ref={targetRef}
                        className={`fw-dropzone ${isDropZoneActive ? 'dropzone-active' : ''}`}
                        title={ !disabled && !readOnly ? '이미지 가져오기' : '' }
                    >
                        {fwUtil.conv.check.nue(value) ? (
                            <div className="fw-dropzone-text fw-column-flex-box">
                                { !disabled && !readOnly ? (
                                    <span>업로드할 파일을 <br/>드래그 앤 드롭 하시거나 <br/>클릭해 주세요</span>
                                ) : (
                                    <span>표시할 이미지가 <br/>없습니다</span>
                                )}
                            </div>
                        ) : (
                            <img className='fw-dropzone-image' src={image} alt={label} />
                        )}                       
                        <i className='dx-icon-photooutline' />
                    </div>
                </div>
                { !disabled && !readOnly &&
                    <FileUploader
                        selectButtonText="찾아보기"
                        uploadMode='useButtons'
                        accept={acceptImageFileExtensions}
                        dialogTrigger={targetRef.current}
                        dropZone={targetRef.current}
                        visible={false}
                        multiple={false}
                        onValueChanged={onValueChanged}
                        onDropZoneEnter={onDropZoneEnter}
                        onDropZoneLeave={onDropZoneLeave}
                    />
                }
                { allowDrawing &&
                    <DrawingButton 
                        width={size}
                        disabled={readOnly}
                        saveCanvas={(file) => onValueChange(file)}
                    />
                }
            </div>            
        </Item>
    );
};

const FormText = (props) => {
    const { label, value, maxLength, mask, readOnly, onValueChange, required, disabled, placeholder, button, hideMarker, colSpan } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-text-item': true, 'accent': required })} colSpan={colSpan || 1}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required && !hideMarker? " *" : ""}
                </div>
                <TextBox
                    value={value}
                    mask={mask}
                    readOnly={readOnly}
                    disabled={disabled}
                    maxLength={maxLength}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    onValueChange={onValueChange}
                    placeholder={placeholder}
                >
                    {button &&
                    <TextButton
                        name={button.name}
                        location={button.loca}
                        options={{
                            text: button.text,
                            stylingMode: button.mode,
                            width: button.width,
                            onClick: button.click,
                            disabled: button.disabled,
                            visible: button.visible,
                            className: button.class,
                        }}  
                    />   
                    }
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};


const FormAuto = (props) => {
    const { label, value, dataSource, maxLength, mask, readOnly, onValueChange, required, disabled, placeholder } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-text-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <Autocomplete
                    value={value}
                    mask={mask}
                    dataSource={dataSource}
                    readOnly={readOnly}
                    disabled={disabled}
                    maxLength={maxLength}
                    minSearchLength={0}
                    openOnFieldClick
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    onValueChange={onValueChange}
                    placeholder={placeholder}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </Autocomplete>
            </div>
        </Item>
    )
};

const FormMemo = (props) => {
    const { label, value, mask, readOnly, onValueChange, required, disabled, placeholder, maxLength } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-memo-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <TextArea
                    value={value}
                    mask={mask}
                    readOnly={readOnly}
                    disabled={disabled}
                    maxLength={maxLength}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    onValueChange={onValueChange}
                    placeholder={placeholder}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </TextArea>
            </div>
        </Item>
    )
};

const FormBool = (props) => {
    const { label, text, value, readOnly, onValueChange, disabled, required } = props;
    const booleanValue = value === 1 || value === true;

    return (
        <Item cssClass={'fw-bool-item'}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <CheckBox 
                    text={text}
                    stylingMode='outlined'
                    value={booleanValue}
                    onValueChange={onValueChange}
                    readOnly={readOnly}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    disabled={disabled}
                />
            </div>
        </Item>
    )
};

const FormRegx = (props) => {
    const { 
        label, value, onValueChange, maxLength,
        required, readOnly, disabled, placeholder, 
        mask, rule, message, pass,
        hide, hideMarker,
        verify,
    } = props;

    const regxMessage = fwUtil.conv.tern(`${label} 형식이 잘못되었습니다.`, message);

    const validateValue = (value) => {
        return rule.test(value);
    };

    const verifyValue = () => {
        return verify?.stat;
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-regx-item': true, 'accent': required, 'hide': hide })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required && !hideMarker? " *" : ""}
                </div>
                <TextBox
                    value={value}
                    mask={mask}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    maxLength={maxLength}
                    onValueChange={onValueChange}
                    placeholder={placeholder}
                    hint={placeholder}
                >
                    <Validator>
                    {!pass && required && 
                    <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />
                    }
                    {!pass &&
                    <CustomRule 
                        message={regxMessage}
                        validationCallback={(e) => validateValue(e.value)}
                    />                        
                    }
                    {!pass && verify &&
                    <CustomRule
                        message={verify.mess}
                        validationCallback={() => verifyValue()}
                    />
                    }                        
                    </Validator>                        
                </TextBox>
            </div>
        </Item>
    )
};

const FormNumb = (props) => {
    const { label, value, length, suffix, readOnly, onValueChange, required, disabled, max } = props;

    let format = '';
    const [integerLength, decimalLength] = length || [null, null];
    const integerPart = '#0';
    const decimalPart = decimalLength !== null && decimalLength !== 0 ? `.${'0'.repeat(decimalLength)}` : '';
    const suffixPart = suffix ? ` ${suffix}` : '';
    const numericValue = typeof value === 'string' ? Number(value) : value;
    format = `${integerPart}${decimalPart}${suffixPart}`;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-numb-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <NumberBox
                    format={format}
                    max={max}
                    value={numericValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    onValueChange={onValueChange}
                    showSpinButtons
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </NumberBox>
            </div>
        </Item>
    )
};

const FormMont = (props) => {
    const { label, mask, readOnly, value, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <DateBox
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    type='date'
                    calendarOptions={{
                        maxZoomLevel: 'year'
                    }}
                    displayFormat={'yyyy-MM'}
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormTime = (props) => {
    const { label, mask, readOnly, value, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const calendarOptions = { visible: false };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <DateBox
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    calendarOptions={calendarOptions}
                    displayFormat={'HH:mm:ss'}
                    stylingMode='outlined'
                    type='datetime'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormDate = (props) => {
    const { label, mask, readOnly, value, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <DateBox
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    type='date'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormDtme = (props) => {
    const { label, value, mask, readOnly, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-dtme-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <DateBox
                    labelMode={'outside'}
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    displayFormat={'yyyy-MM-dd HH:mm:ss'}
                    type='datetime'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormSelc = (props) => {
    const { label, defaultValue, items, displayExpr, valueExpr, value, mask, readOnly, onValueChange, required, disabled, placeholder } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-selc-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <SelectBox
                    items={items}
                    displayExpr={displayExpr}
                    value={value}
                    valueExpr={valueExpr}
                    mask={mask}
                    readOnly={readOnly}
                    disabled={disabled}
                    placeholder={placeholder ? placeholder : "선택해주세요"}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    defaultValue={defaultValue}
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </SelectBox>
            </div>            
        </Item>
    )
};

const FormPass = (props) => {
    const { 
        label, value, onValueChange, maxLength, 
        required, readOnly, disabled, placeholder,
        mask, rule, message, pass,
        hide, hideMarker,
        confirm, password, 
    } = props;

    const regxMessage = fwUtil.conv.tern(`${label} 형식이 잘못되었습니다.`, message);
    const validateValue = (value) => {
        return value === "" ? true : (rule ? rule.test(value) : false);
    };

    const confirmPassword = useCallback(
        ({ value }) => {
            const currentPassword = password;
            if ((!value || value.trim() === '') && (!currentPassword || currentPassword.trim() === '')) {
                return true;
            }
            return value === currentPassword;
        }, [password]
    );

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-text-item': true, 'accent': required, 'hide': hide })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required && !hideMarker? " *" : ""}
                </div>
                <TextBox
                    labelMode={'outside'}
                    mode={'password'}
                    value={value}
                    mask={mask}
                    readOnly={readOnly}
                    disabled={disabled}
                    maxLength={maxLength}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input', autocomplete: "new-password" }}
                    stylingMode='outlined'
                    onValueChange={onValueChange}
                    placeholder={placeholder}
                    hint={placeholder}
                > 
                    <Validator>
                    {!pass && required && 
                    <RequiredRule message={`${label}은(는) 필수 항목입니다.`} /> 
                    }
                    {!pass && confirm && 
                    <CustomRule 
                        message="비밀번호가 일치하지 않습니다." 
                        validationCallback={confirmPassword}
                    /> 
                    }
                    {!pass && rule &&
                    <CustomRule 
                        message={regxMessage}
                        validationCallback={(e) => validateValue(e.value)}
                    />
                    }
                    </Validator>                        
                </TextBox>
            </div>
        </Item>
    )
};

const FormGrid = (props) => {
    const { label, value, mask, readOnly, required, disabled, onClick, placeholder } = props;
    const formLabel = required ? `${label} *` : label;
    const buttonOpt = {
        icon: 'search',
        stylingMode: 'text',
        onClick: !readOnly && onClick
    };
    const onKeyPress = (e) => {
        e.event.preventDefault();  
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-grid-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <div className='fw-input-label'>
                    {label}{required? " *" : ""}
                </div>
                <TextBox
                    label={formLabel}
                    labelMode={'outside'}
                    value={value}
                    mask={mask}
                    disabled={disabled}
                    readOnly={readOnly}
                    onKeyDown={onKeyPress}
                    onKeyUp={onKeyPress}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    placeholder={placeholder}
                >
                    <TextButton
                        name={`fw-${label}-grid-opener`}
                        location='after'
                        options={buttonOpt}
                    />
                    <Validator>
                        {required && <RequiredRule message={`${label}은(는) 필수 항목입니다.`} />}
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormBtns = (props) => {
    const { 
        icon, text, width,
        disabled, hide,
        onClick, 
    } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-btns-item': true, 'hide': hide })}>
            <div className={'fw-btns-wrapper'}>
                <Button
                    icon={icon}
                    width={width}
                    text={text}
                    onClick={onClick}
                    disabled={disabled}
                />
            </div>
        </Item>
    )
};

export {
    FormExce,
    FormImag,
    FormText,
    FormAuto,
    FormMemo,
    FormBool,
    FormRegx,
    FormNumb,
    FormMont,
    FormTime,
    FormDate,
    FormDtme,
    FormSelc,
    FormGrid,
    FormPass,
    FormBtns,
};