import { Button, LoadIndicator } from "devextreme-react"

const AccountFormButton = ({ loading, className, submitBtn, text, children, type, onClick, disabled }) => {
    return (
        <Button
            width={'100%'}
            type={type}
            disabled={disabled || loading}
            className={className}
            useSubmitBehavior={submitBtn}      
            onClick={onClick}      
        >
            <span className="dx-button-text">
                {
                    loading
                        ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                        : text || children
                }
            </span>
        </Button>
    )
};

export {
    AccountFormButton
}