import React from "react";
import { AccountFormButton } from "./account-panel-item";
import { useNavigate } from "react-router-dom";

export function FormJoinWait() {
    const navigate = useNavigate();

    return (
        <div className='auth-wait-container'>
            <div className="wait-wrapper">
                <div className="wait-header">메일 발송 완료</div>
                <div className="wait-body">
                    <div className="body-mess">
                        인증 메일이 성공적으로 발송되었습니다.<br />
                        메일 확인 후 인증 링크를 클릭하여 <strong>인증완료 후</strong>에<br />
                        로그인 해주세요.
                    </div>
                    <div className="body-warn">
                        <div className="warn-title"><i className="dx-icon-errorcircle"></i>이메일을 받지 못했을 경우</div>
                        <ul className="warn-list">
                            <li className="warn-list-item">메일 전송 후 도착까지 시간이 걸릴 수 있습니다. 잠시 기다리신 후 메일함을 새로고침 해보세요.</li>
                            <li className="warn-list-item">메일이 스팸함에 보관되어 있을 수 있습니다. 스팸함을 확인해주세요.</li>
                            <li className="warn-list-item">위 조치에도 불구하고 인증 메일이 도착하지 않는 경우, <a href="mailto:miraecnl.offcial@gmail.com">miraecnl.offcial@gmail.com</a>으로 문의부탁드립니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <AccountFormButton
                text={'로그인'}
                className={'first-btn'}
                type={'default'}
                onClick={() => navigate('/auth/login')}
            />
        </div>
    )
}