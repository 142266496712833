import React, { useCallback, useEffect, useState } from 'react';
import LogoImg from '../../../assets/comp-logo-intr.png';
import { homeData, homeUtil } from './Home-util';
import { Button, LoadPanel } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
//import { fwUtil } from '../../../script/util';
import './home.scss';
import { Animation, Hide, Show } from 'devextreme-react/cjs/load-panel';

//const newsSearchApi = '';

export default function Home() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [newsData, setNewsData] = useState([]);
    const newsDataUpdate = useCallback((e) => setNewsData(e), []);
    const [autoNews, setAutoNews] = useState(true);
    const onClickChanAutoBtns = useCallback(() => setAutoNews(!autoNews), [autoNews]);
    const [newsPage, setNewsPage] = useState(0);
    const onClickNextNewsBtns = useCallback(() => {
        if (!newsData || newsData.length === 0) {
            return;
        }
        setNewsPage((prevPage) => {
            const nextPage = prevPage + 1;
            if (nextPage >= newsData.length) {
                return 0;
            }
            return nextPage;
        });
    }, [newsData]);
    const onClickPrevNewsBtns = useCallback(() => {
        if (!newsData || newsData.length === 0) {
            return;
        }
        setNewsPage((prevPage) => {
            const nextPage = prevPage - 1;
            if (nextPage < 0) {
                return newsData.length - 1;
            }
            return nextPage;
        });
    }, [newsData]);

    const newsSearch = useCallback(() => {
        //const res = fwUtil.apis.search(newsSearchApi);
        const res = homeData.tempNews;
        newsDataUpdate(res);
    }, [newsDataUpdate]);

    useEffect(() => {
        newsSearch();
    }, [newsSearch]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (newsData && newsData.length > 0) {
                if (autoNews) {
                    onClickNextNewsBtns();
                }
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [newsData, autoNews, onClickNextNewsBtns]);

    useEffect(() => {
        const img = new Image();
        img.src = '/image/main/main1.jpg';
        img.onload = () => setLoading(false);
    }, []);

    const onNavClick = useCallback((path) => {
        const container = document.getElementById('fw-content-container');
        container?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        navigate(path)
    }, [navigate]);

    return (
        <>
            <LoadPanel visible={loading} shadingColor='rgba(0,0,0,.8)'>
            </LoadPanel>
            <div className="page-container home">
                <div className="content-box" style={{
                    background: 'url(/image//main/main1.jpg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className="title">
                        <div className="anim-text en">
                            {homeUtil.wrapWithSpan('Charge quickly and easily, anytime, anywhere.')}
                        </div>
                        <div className="anim-text kr">
                            {homeUtil.wrapWithSpan('언제, 어디서나, 빠르고 간편하게 충전하세요.')}
                        </div>
                    </div>
                    <img className='logo' src={LogoImg} alt={'미래CNL'} />
                </div>
                <section className='news-section'>
                    <div className='news-header'>
                        <div className='news-title'>
                            NEWS
                        </div>
                        <div className='news-nav'>
                            <span>MORE</span>
                            <Button
                                icon='send'
                                className='more-btns'
                                onClick={() => onNavClick('/boar/newsstat')}
                            />
                        </div>
                    </div>
                    {newsData && newsData.length > 0 ? (
                        <div className='news-body'>
                            <div className='news-auto-list'>
                                {newsData.map((item, index) => (
                                    <div className={`news-content ${index === newsPage ? 'visible' : 'hidden'}`} key={index}>
                                        <img className={'news-thumb'} src={item.thum_imgs} alt={item.news_titl} />
                                        <div className='news-detail'>
                                            <div className='detail-title'>{item.news_titl || '제목없음'}</div>
                                            <div className='detail-detail'>{item.news_cont || '...'}</div>
                                            <div className='detail-date'>{item.news_date || '0000.00.00'}</div>
                                        </div>
                                    </div>
                                ))}
                                <div className='news-slider'>
                                    <div className='slider-bar-wrapper'>
                                        {Array.from({ length: newsData.length }).map((_, index) => (
                                            <div className={`slider-bar ${index === newsPage ? 'sel' : ''}`} key={index} onClick={() => setNewsPage(index)} />
                                        ))}
                                    </div>
                                    <div className='slider-con'>
                                        <Button
                                            stylingMode='text'
                                            icon='chevronleft'
                                            onClick={onClickPrevNewsBtns}
                                        />
                                        <Button
                                            stylingMode='text'
                                            icon='chevronright'
                                            onClick={onClickNextNewsBtns}
                                        />
                                        <Button
                                            text={autoNews ? '❚❚' : '▶'}
                                            stylingMode='text'
                                            onClick={onClickChanAutoBtns}
                                        />
                                        <div className='slider-num'><span className='current-page'>{newsPage + 1}</span> / {newsData.length}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='news-fixs-list'>
                                {newsData && newsData.length > 0 && newsData.map((item, index) => (
                                    <div className='news-list' key={index}>
                                        <div className='news-title'>{item.news_titl || '제목없음'}</div>
                                        <div className='news-date'>{item.news_date || '0000.00.00'}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className='news-body'>
                            <div className='none-mess'>NEWS 정보를 불러올 수 없습니다.</div>
                        </div>
                    )}
                </section>
            </div>
        </>
    );
}
