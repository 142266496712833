import React from "react";
import { Button } from "devextreme-react";
import { fwUtil } from "../../util";
import "./pays-card.scss";

const PaysCard = (props) => {
    const { type, data, ins, upd, del, edit, starClick } = props;
    const allowEdit = fwUtil.conv.tern(true, edit);

    if (type === "default") {
        return (
            <div className={'fw-pays-card-box default'} onClick={ins}>
                <div className={'fw-pays-card-add-icon'}>+</div>
                <div className={'fw-pays-card-add-mes'}>추가하기</div>
            </div>
        )
    }

    if (!data) {
        return;
    }
    
    return (
        <div className={`fw-pays-card-box real ${data.cred_colo}`}>
            <div className={'fw-pays-card-header'}>
                <div className={'fw-pays-card-name-wrapper'}>
                    <div className={'fw-pays-card-name'}>{data.cred_name || '-'}</div>
                    {allowEdit && starClick && <div className={`fw-pays-card-favo ${data.cred_yesn ? 'favo' : 'comm'}`} title="주 사용카드" onClick={() => starClick(data)}>★</div>}
                </div>
                <div className={'fw-pays-card-btns-wrapper'}>
                    {upd &&
                        <Button
                            icon="edit"
                            stylingMode="text"
                            onClick={() => upd(data)}
                        />
                    }
                    {del &&
                        <Button
                            icon="trash"
                            stylingMode="text"
                            onClick={() => del(data)}
                        />
                    }
                </div>
            </div>
            <div className={'fw-pays-card-detail'}>
                <div className={'fw-pays-card-numb'}>{data.cred_view || '0000000000000000'}</div>
            </div>
        </div>
    )
};

export {
    PaysCard,
}