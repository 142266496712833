import { AccountPanel } from "../../../script/components";
import { intrComp, suppComp } from "../../../../utils/default-comp";
import { ContentHeader } from "../../../script/components/header/Header";
import FormFindI from "../../../script/components/account-panel/FormFindI";
import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";

export default function AuthFindI() {
    const { user } = useAuth();
    const navigate = useNavigate();

    if (user) { navigate('/home') }

    return (
        <>
            <ContentHeader
                root={'home'}
                rootLink={'/home'}
                path={['회원서비스', '아이디찾기']}
            />
            <AccountPanel title="아이디찾기" detail="찾으시려는 아아디의 이메일을 입력해주세요" intrComp={intrComp} suppComp={suppComp} >
                <FormFindI />
            </AccountPanel>
        </>
    )
}