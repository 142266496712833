import React from "react";
import { Link, useLocation } from "react-router-dom";
import './panel-cust.scss';

export function CustomTabPanel({ data }) {
    const location = useLocation();

    return (
        <div className="fw-custom-tab">
            {data && data.length > 0 && data.map((item, index) => {
                const isSelected = location.pathname === item.link;

                return (
                    <Link 
                        key={index} 
                        to={item.link} 
                        className={`custom-tab-item ${isSelected ? 'selc' : ''}`}
                    >
                        <i className={`dx-icon dx-icon-${item.icon}`}></i>
                        <div className={'tab-title'}>{item.name}</div>
                    </Link>
                );
            })}
        </div>
    )
}