import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigation } from './contexts/navigation';
import { intrComp, suppComp } from './utils/default-comp';
import { Footer, SideNavToolbar as SideNavBarLayout } from './focuswin/script/components';
import routes from './app-routes';

export default function CmsContent() {
  const { routeLoading } = useNavigation();

  if (routeLoading) {
    return;
  }

  return (
    <SideNavBarLayout intrComp={intrComp} suppComp={suppComp} menu={"cms"} >
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
      <Footer />
    </SideNavBarLayout>
  );
}

