import React, { useState, useCallback } from 'react';
import Form from 'devextreme-react/form';
import { FormRegx } from '../form/form-items';
import { ValidationGroup } from 'devextreme-react';
import { Link, useNavigate } from 'react-router-dom';
import { AccountFormButton } from './account-panel-item';
import { fwUtil } from '../../util';

const defaultData = {
  mail_idno: '',
};

const mailRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default function FormFindI() {
  // 시스템
  const navigate = useNavigate();
  // 페이지 상태값
  const [loading, setLoading] = useState(false);
  // 데이터
  const [data, setData] = useState(defaultData);
  const dataUpdate = useCallback((e) => setData(e), [setData]);

  // 로그인
  const onSubmit = useCallback(async () => {

    const postData = {
      mailIdno: data.mail_idno,
    }
    setLoading(true);
    const res = await fwUtil.apis.search('아이디찾기 API', postData);
    if (!res.isOk) {
      setLoading(false);
      fwUtil.aler.toast.erro('메일 전송에 실패하였습니다.');
    }
  }, [data]);

  // 로그인 버튼 클릭
  const handleFindI = useCallback((e) => {
    e.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const onNavigateLoginBtnsClick = useCallback(() => {
    navigate('/auth/login');
  }, [navigate]);

  // 수정
  const updateField = (field) => (value) => {
    if (!data) return;

    dataUpdate({ ...data, [field]: value });
  };

  return (
    <div id={'findi-form'} className={'account-form'}>
      <ValidationGroup>
        <form onSubmit={handleFindI}>
          <Form className='account-panel-form'>
            {FormRegx({ value: data.mail_idno, onValueChange: updateField('mail_idno'), label: '이메일', rule: mailRule, required: true, hideMarker: true, placeholder: '이메일' })}
          </Form>
          <AccountFormButton 
            loading={loading}
            submitBtn={true}
            className={'first-btn'}
            text={'메일발송'}
            type={'default'}
          />          
          <AccountFormButton 
            className={'account-btn'}
            text={'로그인'}
            type={'normal'}
            onClick={onNavigateLoginBtnsClick}
          />          
          <div className={'link'}>
            비밀번호가 기억나지 않으신가요?<Link to={'/auth/findp'}>비밀번호 찾기</Link>
          </div>         
        </form>
      </ValidationGroup>
    </div>
  );
};