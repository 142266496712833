import React, { useState, useCallback, useEffect } from 'react';
import { tempTermData } from '../../../../utils/terms';
import { FormJoinTerm } from './FormJoins-term';
import { FormJoinWays } from './FormJoins-ways';
import FormJoinMail from './FormJoins-mail';
import { FormJoinWait } from './FormJoins-wait';

const defaultTermStat = {
  priv_poli: false,
  term_serv: false,
  loca_serv: false,
}

export default function FormJoins() {
  // 페이지 상태값
  const [joinLeve, setJoinLeve] = useState(0);
  // 데이터
  const [termData, setTermData] = useState([]);
  const [termStat, setTermStat] = useState(defaultTermStat);
  const termDataUpdate = useCallback((e) => setTermData(e), [setTermData]);
  const termStatUpdate = useCallback((e) => setTermStat(e), [setTermStat]);

  // 다음 버튼 클릭
  const onJoinLeveBtnsClick = useCallback((e) => {
    const container = document.getElementById('fw-content-container');
    container?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setJoinLeve(e);
  }, []);

  const getTerm = useCallback(async () => {
    //const res = await fwUtil.apis.search(termSearchApi);
    const res = tempTermData;
    termDataUpdate(res);
  }, [termDataUpdate]);

  const termAllCheckBoxClick = useCallback((e) => {
    const updaTermStat = {
      priv_poli: e,
      term_serv: e,
      loca_serv: e,
    };
    setTermStat(updaTermStat);
    if (e) {
      const container = document.getElementById('fw-content-container');
      container?.scrollTo({
        top: 1000,
        behavior: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    getTerm();
  }, [getTerm]);

  // 수정
  const updateTerm = (field) => (value) => {
    if (!termStat) return;

    termStatUpdate({ ...termStat, [field]: value });
  };

  if (joinLeve === 0) {
    return (
      <FormJoinTerm
        termData={termData}
        termStat={termStat}
        termAllCheckBoxClick={termAllCheckBoxClick}
        onJoinLeveBtnsClick={onJoinLeveBtnsClick}
        updateTerm={updateTerm}
      />
    )
  }
  if (joinLeve === 1) {
    return (
      <FormJoinWays
        onJoinLeveBtnsClick={onJoinLeveBtnsClick}
      />
    )
  }
  if (joinLeve === 2) {
    return (
      <FormJoinMail
        onJoinLeveBtnsClick={onJoinLeveBtnsClick}
      />
    )
  }  
  if (joinLeve === 10) {
    return (
      <FormJoinWait />
    )
  }    
};