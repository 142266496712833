import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './Header.scss';
import { useNavigate } from 'react-router-dom';

function LayoutHeader({ menuToggleEnabled, toggleMenu }) {
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
      </Toolbar>
    </header>
  )
}

function ContentHeader({ root, rootLink, path }) {
  const navigate = useNavigate();

  return (
    <div className='fw-content-header'>
      <div className='header-root' onClick={() => navigate(rootLink)}>
        <i className={`dx-icon-${root}`}></i>
      </div>
      {path && path.length > 0 &&
        <div className='header-path'>
          <div className='header-path-item'>{path[0]}&nbsp;&nbsp;▶</div>
          <div className='header-path-item'>&nbsp;&nbsp;{path[1]}</div>
        </div>
      }
    </div>
  )
}

export {
  LayoutHeader,
  ContentHeader
}