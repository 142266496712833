import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { CreateTreeNavigation }  from '../../../../app-navigation';
import { useNavigation } from '../../../../contexts/navigation';
import * as events from 'devextreme/events';
import './SideNavigationMenu.scss';

export default function SideNavigationMenu(props) {
  const {
    children,
    treeViewRef,
    selectedItemChanged,
    compactMode,
    onMenuReady,
  } = props;

  function normalizePath () {
    const navigation = CreateTreeNavigation();
    return navigation.map((item) => (
      { ...item, expanded: false, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
    ))
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { navigationData: { currentPath } } = useNavigation();
  
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((e) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }
  }, []);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    } 
  }, [currentPath, compactMode, treeViewRef]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          width={'100%'}
          keyExpr={'path'}
          expandEvent={'click'}
          selectionMode={'single'}
          focusStateEnabled={false}        
          items={items}
          ref={treeViewRef}
          onContentReady={onMenuReady}
          onItemClick={selectedItemChanged}
        />
      </div>
    </div>
  );
}
