import Button from 'devextreme-react/button';
import Drawer from 'devextreme-react/drawer';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SideNavigationMenu, Footer } from '../../components';
//import { LayoutHeader } from '../header/Header';
import { useScreenSize } from '../../../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../../../utils/patches';
import { useAuth } from '../../../../contexts/auth';
import { PopupAler } from '../popup/popup';
import './side-nav-inner-toolbar.scss';

export default function SideNavInnerToolbar({ intrComp, menu, children }) {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { isXSmall, isLarge } = useScreenSize();
  const scrollRef = useRef(null);
  const treeViewRef = useRef(null);
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed);
  const [signoutPopupVisible, setSignoutPopupVisible] = useState(false);
  const signoutHide = useCallback(() => {
    setSignoutPopupVisible(false);
  }, []);
  const [fadeIn, setFadeIn] = useState(true);

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const onNavigationChanged = useCallback(({ itemData, event }) => {
    if (menuStatus === MenuStatus.Closed || !itemData.path) {
      event.preventDefault();
      setMenuStatus(MenuStatus.Opened);
      return;
    }

    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setFadeIn(true);
    navigate(itemData.path);
    setFadeIn(false);

    setMenuStatus(MenuStatus.Closed);
    event.stopPropagation();
  }, [navigate, menuStatus]);

  const onNavToolbarClick = useCallback((act, pram) => {
    if (act === 'nav') {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      setMenuStatus(MenuStatus.Closed);
      navigate(pram);
    }
  }, [navigate])

  useEffect(() => {
    setFadeIn(false);
  }, []);

  return (
    <div className={'side-nav-inner-toolbar'}>
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        openedStateMode={'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 100}
        maxSize={window.innerWidth}
        template={'menu'}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
      >
        <div ref={scrollRef} id={'fw-content-container'} className={'container'}>
          <div className={`fade ${fadeIn ? 'active' : ''}`}></div>
          <div className={'content'}>
            {React.Children.map(children, (item) => {
              return item.type !== Footer && item;
            })}
          </div>
          <div className={'content-block'}>
            {React.Children.map(children, (item) => {
              return item.type === Footer && item;
            })}
          </div>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            treeViewRef={treeViewRef}
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            onMenuReady={onMenuReady}
            menu={menu}
          >
            <Toolbar id={'navigation-header'}>
              {
                !isXSmall &&
                <Item
                  location={'before'}
                  cssClass={'menu-button'}
                >
                  <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>
              }
              <Item location={'before'} cssClass={'header-title'}>
                <img className={'comp-imag'} src={intrComp?.logo} alt={`${intrComp?.name} 로고`} onClick={() => onNavToolbarClick('nav', '/home')} />
              </Item>
              {!user && <Item
                location={'after'}
              >
                <Button
                  text='로그인'
                  stylingMode='text'
                  className='header-btn'
                  onClick={() => onNavToolbarClick('nav', '/auth/login')}
                />
              </Item>
              }
              {!user &&
                <Item
                  location={'after'}
                >
                  <Button
                    text='회원가입'
                    stylingMode='text'
                    className='header-btn'
                    onClick={() => onNavToolbarClick('nav', '/auth/joins')}

                  />
                </Item>
              }
              {user &&
                <Item
                  location={'after'}
                >
                  <Button
                    text='내 정보'
                    stylingMode='text'
                    className='header-btn'
                    onClick={() => onNavToolbarClick('nav', '/user/infostat')}
                  />

                </Item>
              }
              {user &&
                <Item
                  location={'after'}
                >
                  <Button
                    text='Q&A'
                    stylingMode='text'
                    className='header-btn'
                    onClick={() => onNavToolbarClick('nav', '/boar/qnasstat')}
                  />
                </Item>
              }
              {user &&
                <Item
                  location={'after'}
                >
                  <Button
                    text='로그아웃'
                    stylingMode='text'
                    className='header-btn'
                    onClick={() => setSignoutPopupVisible(true)}
                  />
                </Item>
              }
            </Toolbar>
          </SideNavigationMenu>
        </Template>
      </Drawer>
      <PopupAler
        visible={signoutPopupVisible}
        hiding={signoutHide}
        confirm={signOut}
        message={'로그아웃 하시겠습니까?'}
      />
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};

