import { CheckBox } from "devextreme-react";
import { AccountFormButton } from "./account-panel-item";

export function FormJoinTerm({ termData, termStat, termAllCheckBoxClick, onJoinLeveBtnsClick, updateTerm }) {
    if (!termStat) {
        return;
    }
    
    return (
        <div className='auth-term-container'>
            <div className='term-title'>이용약관</div>
            <div className='term-detail'>회원가입 전, 약관을 확인해주세요.</div>
            <div className='term-all-check'>
                <CheckBox iconSize={20} onValueChange={termAllCheckBoxClick} />
                <div className='all-check-mes'>전체동의</div>
            </div>
            {termData && termData.length > 0 && termData.map((item, index) => (
                <div className='term-content' key={index}>
                    <div className='term-head'>
                        <CheckBox iconSize={20} value={index === 0 ? termStat.priv_poli : index === 1 ? termStat.term_serv : termStat.loca_serv} onValueChange={updateTerm(index === 0 ? 'priv_poli' : index === 1 ? 'term_serv' : 'loca_serv')} />
                        <div className={`term-type ${item.requ_yesn ? 'requ' : 'selc'}`}>{item.requ_yesn ? '[필수]' : '[선택]'}</div>
                        <div className='term-name'>{item.term_name}</div>
                    </div>
                    <div className='term-memo' dangerouslySetInnerHTML={{ __html: item.term_memo }}></div>
                </div>
            ))}
            <AccountFormButton
                onClick={() => onJoinLeveBtnsClick(1)}
                className={'first-btn'}
                text={'다음'}
                type={'default'}
                disabled={!termStat.priv_poli || !termStat.term_serv}
            />
        </div>
    )
}