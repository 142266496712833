import React, { useState, useCallback } from 'react';
import Form from 'devextreme-react/form';
import { ValidationGroup } from 'devextreme-react';
import { FormText, FormPass, FormRegx } from '../../../script/components/form/form-items';
import { AccountFormButton } from './account-panel-item';
import { fwUtil } from '../../util';
import { PopupWarn } from '../popup/popup';

const defaultData = {
  id: '',
  pwd: '',
  pwd_conf: '',
  user_name: '',
  mail_idno: '',
};

// 정규표현식
const mailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const mailPlaceholder = "이메일";
const idnoRegx = /^[a-zA-Z0-9]{4,20}$/;
const idnoPlaceholder = "아이디(영문자 및 숫자 4~20자리)";
const passRegx = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|;:',.<>?/~`"]).{8,20}$/;
const passPlaceholder = "비밀번호(영문자, 숫자, 특수문자 필수 포함 8~20자리)";

// API
const idnoCheckApi = '/public/api/memb/idno/check/search';
const mailCheckApi = '/public/api/memb/mail/check/search';
const mailSendApi = '/public/api/memb/apply/mail/send';

export default function FormJoinMail({ onJoinLeveBtnsClick }) {
  // 페이지 상태값
  const [loading, setLoading] = useState(false);
  const [idnoChec, setIdnoChec] = useState(false);
  const [mailChec, setMailChec] = useState(false);
  // 데이터
  const [data, setData] = useState(defaultData);
  const dataUpdate = useCallback((e) => setData(e), [setData]);
  // 메일 전송 팝업창
  const [mailSubmitAlertVisible, setMailSubmitAlertVisible] = useState(false);
  const mailSubmitAlertHide = useCallback(() => setMailSubmitAlertVisible(false), []);

  // 로그인
  const onSubmit = useCallback(async () => {

    const postData = {
      id: data.id || '',
      pwd: data.pwd || '',
      membOkay: true,
      userName: data.user_name || '',
      mailIdno: data.mail_idno || '',
    }

    setLoading(true);
    const res = await fwUtil.apis.return(mailSendApi, postData);
    if (!res || !res.status) {
      fwUtil.aler.toast.erro(res?.message || '서버와 연결할 수 없습니다.');
      mailSubmitAlertHide();
      setLoading(false);
      return;
    }
    setLoading(false);
    onJoinLeveBtnsClick(10);
  }, [data, mailSubmitAlertHide, onJoinLeveBtnsClick]);

  // 로그인 버튼 클릭
  const handleJoins = useCallback((e) => {
    e.preventDefault();
    setMailSubmitAlertVisible(true);
  }, []);

  // 수정
  const updateField = (field) => async (value) => {
    if (!data) return;
    if (field === 'id') {
      const regx = idnoRegx.test(value);
      if (regx) {
        setLoading(true);
        const sendData = { userIdno: value }
        const chec = await fwUtil.apis.search(idnoCheckApi, sendData);
        if (chec.idno_chek) {
          fwUtil.aler.toast.info('사용 가능한 아이디입니다.');
          setIdnoChec(true);
        } else {
          fwUtil.aler.toast.erro('사용할 수 없는 아이디입니다!');
          setIdnoChec(false);
        }
        setLoading(false);
      }
    }
    if (field === 'mail_idno') {
      const regx = mailRegx.test(value);
      if (regx) {
        setLoading(true);
        const sendData = { mailIdno: value }
        const chec = await fwUtil.apis.search(mailCheckApi, sendData);
        if (chec.mail_chek) {
          fwUtil.aler.toast.info('사용 가능한 이메일입니다.');
          setMailChec(true);
        } else {
          fwUtil.aler.toast.erro('사용할 수 없는 이메일입니다!');
          setMailChec(false);
        }
        setLoading(false);
      }
    }
    dataUpdate({ ...data, [field]: value });
  };

  return (
    <div id={'login-form'} className={'login-form'}>
      <ValidationGroup>
        <form onSubmit={handleJoins}>
          <Form className='account-panel-form'>
            {FormText({ value: data.user_name, onValueChange: updateField('user_name'), label: '이름', required: true, hideMarker: true, placeholder: '이름', maxLength: 150, readOnly: loading })}
            {FormRegx({ value: data.mail_idno, onValueChange: updateField('mail_idno'), label: '이메일', rule: mailRegx, required: true, hideMarker: true, placeholder: mailPlaceholder, maxLength: 100, readOnly: loading, verify: { stat: mailChec, mess: '이미 존재하는 이메일입니다' } })}
            {FormRegx({ value: data.id, onValueChange: updateField('id'), label: '아이디', rule: idnoRegx, required: true, hideMarker: true, placeholder: idnoPlaceholder, readOnly: loading, verify: { stat: idnoChec, mess: '이미 존재하는 아이디입니다' } })}
            {FormPass({ value: data.pwd, onValueChange: updateField('pwd'), label: '비밀번호', rule: passRegx, required: true, hideMarker: true, placeholder: passPlaceholder, readOnly: loading })}
            {FormPass({ value: data.pwd_conf, onValueChange: updateField('pwd_conf'), label: '비밀번호 확인', rule: passRegx, required: true, hideMarker: true, placeholder: '비밀번호 확인', confirm: true, password: data.pwd, readOnly: loading })}
          </Form>
          <AccountFormButton
            loading={loading}
            submitBtn={true}
            className={'first-btn'}
            text={'가입하기'}
            type={'default'}
          />
        </form>
      </ValidationGroup>
      <PopupWarn
        loading={loading}
        visible={mailSubmitAlertVisible}
        hiding={mailSubmitAlertHide}
        warning={data.mail_idno}
        message={<>
          해당 메일로 인증 확인 메일을 발송합니다<br />
          수신 확인이 되지 않으실 경우 스팸함을 확인해주세요
        </>}
        confirm={onSubmit}
      />
    </div>
  );
};